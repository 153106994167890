import CardDisplay from "src/components/generic/CardDisplay";
import AdditionalAttribute from "../../generic/AdditionalAttribute";
import Offer from "../Offer";

export default function BankOnline() {
  return (
    <CardDisplay title="Bank Online Info">
      <Offer />
      <AdditionalAttribute />
    </CardDisplay>
  );
}
