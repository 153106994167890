import { getParametersByDomain } from "src/api";
import { update } from "../slices/parameterSlice";

export const fetchSupportedLanguages = () => async (dispatch) => {
  try {
    const res = await getParametersByDomain("SUPPORTED_LANGUAGE");
    dispatch(update(res));
  } catch (err) {
    console.error(err.message);
  }
};
