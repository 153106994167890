import { Timeline } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

export default function VersionsTimeLine(props) {
  const { versions, path } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  return versions.length ? (
    <Timeline mode="right">
      {versions.map((version, index) => {
        return (
          <Timeline.Item
            key={version.id}
            color={location.pathname.endsWith(version.id) ? "green" : "orange"}
            label={"Version : " + version.version}
          >
            <h1
              className="cursor-pointer"
              onClick={() => {
                navigate(path + version.id);
                window.location.reload();
              }}
            >
              {t("versions.view")}
            </h1>
          </Timeline.Item>
        );
      })}
    </Timeline>
  ) : (
    <div className="flex justify-center my-5">{t("versions.noAdded")}</div>
  );
}

VersionsTimeLine.propTypes = {
  versions: PropTypes.array.isRequired,
};
