import ArrayAccordion from "src/components/generic/ArrayAccordion";
import { PRODUCT_OFFER_DEFAULT } from "src/utils/defaultsSupplier";
import ProductOfferForm from "../ProductOfferForm/ProductOfferForm";

const ProductOffer = (props) => {
  const { namePrefix } = props;
  return (
    <ArrayAccordion
      name={`${namePrefix}.productOfferList`}
      translationPrefix="productOfferList"
      Form={ProductOfferForm}
      defaultValues={PRODUCT_OFFER_DEFAULT}
    />
  );
};

export default ProductOffer;
