import CardDisplay from "../../../../generic/CardDisplay";
import AdditionalAttribute from "../../generic/AdditionalAttribute";
import Option from "../Option";

const Bancass = () => {
  return (
    <CardDisplay title="Bancass Info">
      <Option />
      <AdditionalAttribute />
    </CardDisplay>
  );
};

export default Bancass;
