import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";

export default function useModal() {
  const [open, setOpen] = useState(false);
  const MyModal = useMemo(
    () =>
      function (props) {
        const { children, title, widthFull } = props;
        return (
          <Modal
            width={widthFull}
            title={<div className="flex justify-center">{title}</div>}
            open={open}
            closable
            footer={[]}
            onCancel={() => setOpen(false)}
          >
            {children}
          </Modal>
        );
      },
    [open]
  );
  MyModal.propTypes = {
    children: PropTypes.element.isRequired,
    title: PropTypes.string.isRequired,
    className: PropTypes.object,
  };

  return { Modal: MyModal, setOpen };
}
