import { useTranslation } from "react-i18next";
import CardDisplay from "src/components/generic/CardDisplay";
import DatePicker from "src/components/generic/inputs/DatePicker";
import SwitchInput from "src/components/generic/inputs/SwitchInput";

export default function Status() {
  const { t } = useTranslation();
  return (
    <CardDisplay title={t("status.title")}>
      <SwitchInput name="active" label={t("status.active")} />
      <div className="">
        <h2 className="my-2"> {t("status.period")} </h2>
        <div className="grid grid-cols-1 gap-3 mt-5">
          <DatePicker
            label={t("status.startDate")}
            name="activationStartDate"
            required
          />
          <DatePicker
            label={t("status.endDate")}
            name="activationEndDate"
            required
          />
        </div>
      </div>
    </CardDisplay>
  );
}
