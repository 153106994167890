import PropTypes from "prop-types";
import { OPTION_DETAILS_DEFAULT } from "../../../../../utils/defaultsSupplier";
import ArrayAccordion from "../../../../generic/ArrayAccordion";
import OptionDetailsForm from "../OptionDetailsForm";

const OptionDetails = (props) => {
  const { namePrefix } = props;
  return (
    <ArrayAccordion
      name={`${namePrefix}.optionDetails`}
      translationPrefix="optionDetails"
      Form={OptionDetailsForm}
      defaultValues={OPTION_DETAILS_DEFAULT}
      fieldsNames={["functionalId", "label", "plafond"]}
    />
  );
};

OptionDetails.propTypes = {
  namePrefix: PropTypes.string.isRequired,
};

export default OptionDetails;
