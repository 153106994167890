import PropTypes from "prop-types";
import clsx from "clsx";
import { Card } from "antd";

export default function CardDisplay(props) {
  const { title, className, titleCentered, hasShadow, children } = props;

  return (
    <Card
      title={
        titleCentered ? (
          <div className="flex justify-center items-center">{title}</div>
        ) : (
          title
        )
      }
      className={clsx("w-full mb-3", className, !hasShadow && "shadow-none")}
      sx={{
        borderRadius: "18px",
      }}
    >
      {children}
    </Card>
  );
}

CardDisplay.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  hasShadow: PropTypes.bool,
  children: PropTypes.any,
};

CardDisplay.defaultProps = {
  hasShadow: true,
};
