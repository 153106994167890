import {
  DropboxOutlined,
  FieldTimeOutlined,
  FileTextOutlined,
  GroupOutlined,
  HourglassOutlined,
  PieChartOutlined,
  QrcodeOutlined,
  TagOutlined,
  TagsOutlined,
} from "@ant-design/icons";
import { Col, Row } from "antd";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CardDisplay from "src/components/generic/CardDisplay";
import InternalisationInput from "src/components/generic/inputs/InternalisationInput";
import ParameterMultiSelect from "src/components/generic/inputs/ParameterMultiSelect";
import ParameterSelect from "src/components/generic/inputs/ParameterSelect";
import SwitchInput from "src/components/generic/inputs/SwitchInput";
import TextInput from "src/components/generic/inputs/TextInput";
export default function MainInfo() {
  const { isUpdateForm } = useFormContext();
  const { t } = useTranslation();
  return (
    <CardDisplay title={t("displayCard.mainInfo")}>
      <div>
        <Row gutter={[16, 12]} className="m-1">
          <Col span={12}>
            <TextInput
              label={t("mainInfo.functionalId")}
              name="functionalId"
              disabled={isUpdateForm}
              startAdornment={<QrcodeOutlined style={{ fontSize: "25px" }} />}
            />
          </Col>
          <Col span={12}>
            <InternalisationInput
              name="name"
              label={t("mainInfo.name")}
              startAdornment={<TagOutlined style={{ fontSize: "25px" }} />}
            />
          </Col>

          <Col span={12}>
            <InternalisationInput
              name="description"
              label={t("mainInfo.description")}
              startAdornment={<FileTextOutlined style={{ fontSize: "25px" }} />}
            />
          </Col>

          <Col span={12}>
            <ParameterSelect
              name="family"
              label={t("mainInfo.family")}
              domain="FAMILY"
              startAdornment={<PieChartOutlined style={{ fontSize: "25px" }} />}
            />
          </Col>

          <Col span={12}>
            <TextInput
              label={t("mainInfo.periodeAmount")}
              name="periodAmount"
              startAdornment={
                <FieldTimeOutlined style={{ fontSize: "25px" }} />
              }
            />
          </Col>
          <Col span={12}>
            <ParameterSelect
              name="periodValidity"
              label={t("mainInfo.periodeValidity")}
              domain="PERIODICITY"
              startAdornment={
                <HourglassOutlined style={{ fontSize: "25px" }} />
              }
            />
          </Col>
          <Col span={12}>
            <ParameterSelect
              name="provider"
              label={t("mainInfo.provider")}
              domain="PROVIDER"
              startAdornment={<DropboxOutlined style={{ fontSize: "25px" }} />}
            />
          </Col>
          <Col span={12}>
            <SwitchInput name="renewable" label={t("mainInfo.renewable")} />
          </Col>
          <Col span={12}>
            <ParameterMultiSelect
              domain="GROUP"
              name="groups"
              label={t("mainInfo.groups")}
              startAdornment={<GroupOutlined style={{ fontSize: "25px" }} />}
            />
          </Col>
          <Col span={12}>
            <ParameterMultiSelect
              domain="TAG"
              name="tagList"
              label={t("mainInfo.tags")}
              startAdornment={<TagsOutlined style={{ fontSize: "25px" }} />}
            />
          </Col>
        </Row>
      </div>
    </CardDisplay>
  );
}
