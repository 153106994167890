import { FileTextOutlined, PercentageOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import ConstraintsAdd from "src/components/constraint/form/ConstraintsAdd";
import DatePicker from "src/components/generic/inputs/DatePicker";
import SwitchInput from "src/components/generic/inputs/SwitchInput";
import TextInput from "src/components/generic/inputs/TextInput";
import InternalisationInput from "../inputs/InternalisationInput";

const DiscountForm = (props) => {
  const { namePrefix } = props;
  const { t } = useTranslation();
  return (
    <Row gutter={[16, 12]}>
      <Col span={8}>
        <InternalisationInput
          name={`${namePrefix}.description`}
          label={t("discount.description")}
          startAdornment={<FileTextOutlined style={{ fontSize: "25px" }} />}
        />
      </Col>

      <Col span={8}>
        <TextInput
          className="mr-2"
          label={t("discount.percentage")}
          type="number"
          name={`${namePrefix}.rate`}
          startAdornment={<PercentageOutlined style={{ fontSize: "25px" }} />}
        />
      </Col>
      <Col span={8}>
        <SwitchInput name={`${namePrefix}.active`} label="Active" />
      </Col>
      <Col span={24}>
        <DatePicker
          label={t("discount.startDate")}
          name={`${namePrefix}.dateFrom`}
        />
      </Col>
      <Col span={24}>
        <DatePicker
          label={t("discount.endDate")}
          name={`${namePrefix}.dateTo`}
        />
      </Col>
      <Col span={24}>
        <p className="font-medium">{t("discount.constraints")}</p>
        <ConstraintsAdd namePrefix={namePrefix} className="flex-wrap" />
      </Col>
    </Row>
  );
};

export default DiscountForm;
