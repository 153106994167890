import React from "react";
import { Pagination } from "antd";

export default function CustomPagination(props) {
  const { total, pageSize, current, onChange } = props;
  return (
    <div className="w-full flex items-center justify-center">
      <Pagination
        current={current}
        total={total}
        pageSize={pageSize}
        onChange={onChange}
        showSizeChanger={false}
        hideOnSinglePage
      />
    </div>
  );
}
