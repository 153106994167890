import {
  DollarCircleOutlined,
  FileTextOutlined,
  ProfileOutlined,
  QrcodeOutlined,
  TagOutlined,
} from "@ant-design/icons";
import { Col, Row } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ImageUpload from "src/components/generic/inputs/ImageUpload";
import InternalisationInput from "src/components/generic/inputs/InternalisationInput";
import SwitchInput from "src/components/generic/inputs/SwitchInput";
import TextInput from "src/components/generic/inputs/TextInput";
import Formula from "../Formula";

const ThemeForm = (props) => {
  const { namePrefix } = props;
  const { t } = useTranslation();
  return (
    <div>
      <h1>Info </h1>

      <Row gutter={[16, 12]}>
        <Col span={12}>
          <ImageUpload name={`${namePrefix}.thumbnail`} label="Thumbnail" />
        </Col>
        <Col span={12}>
          <ImageUpload name={`${namePrefix}.largeImage`} label="Large Image" />
        </Col>
        <Col span={12}>
          <TextInput
            label="Theme Code"
            name={`${namePrefix}.functionalId`}
            startAdornment={<QrcodeOutlined style={{ fontSize: "25px" }} />}
          />
        </Col>
        <Col span={12}>
          <InternalisationInput
            name={`${namePrefix}.themeDescription`}
            label="Description"
            startAdornment={<FileTextOutlined style={{ fontSize: "25px" }} />}
          />
        </Col>

        <Col span={12}>
          <InternalisationInput
            name={`${namePrefix}.themeLabel`}
            label="Label"
            startAdornment={<TagOutlined style={{ fontSize: "25px" }} />}
          />
        </Col>
        <Col span={12}>
          <InternalisationInput
            name={`${namePrefix}.themeDetails`}
            label="Details"
            startAdornment={<ProfileOutlined style={{ fontSize: "25px" }} />}
          />
        </Col>
        <Col span={12}>
          <TextInput
            label={t("theme.additionalPrice")}
            name={`${namePrefix}.additionalPrice`}
            type="number"
            startAdornment={
              <DollarCircleOutlined style={{ fontSize: "25px" }} />
            }
          />
        </Col>
        <Col span={12}>
          <SwitchInput
            label={t("theme.salable")}
            name={`${namePrefix}.salable`}
          />
        </Col>
        <Col span={24}>
          <Formula namePrefix={namePrefix} />
        </Col>
      </Row>
    </div>
  );
};

ThemeForm.propTypes = {
  namePrefix: PropTypes.string.isRequired,
};

export default ThemeForm;
