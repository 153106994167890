import {
  mapDataToInternationalisaion,
  mapInternationalisationToData,
} from "../../mapperHelpers";

export const mapPackToData = (pack) => {
  return {
    formulaList: pack.formulaList.map((formula) =>
      mapPackFormulasToData(formula)
    ),
  };
};

const mapPackFormulasToData = (elem) => {
  return {
    functionalId: elem.functionalId,
    label: mapInternationalisationToData(elem.label),
    additionalPrice: elem.additionalPrice,
    salable: elem.salable,
    productOfferList: elem.productOfferList.map((productOffer) =>
      mapProductOfferToData(productOffer)
    ),
  };
};

const mapProductOfferToData = (elem) => {
  return {
    functionalId: elem.functionalId,
    label: mapInternationalisationToData(elem.label),
    productOption: elem.productOption.map((prodOpt) =>
      mapProductOptionPairToData(prodOpt)
    ),
    optional: elem.optional,
  };
};
const mapProductOptionPairToData = (elem) => {
  return {
    product: elem.product,
    options: elem.options,
  };
};

export const mapDataToPack = (data) => {
  const formulaList = data.formulaList.map((elem) =>
    mapDataToPackFormulas(elem)
  );
  return {
    formulaList,
    type: "pack",
  };
};

const mapDataToPackFormulas = (elem) => {
  return {
    functionalId: elem.functionalId,
    label: mapDataToInternationalisaion(elem.label),
    additionalPrice: elem.additionalPrice,
    salable: elem.salable,
    productOfferList: elem.productOfferList.map((prodOffList) =>
      mapDataToProductOffer(prodOffList)
    ),
  };
};

const mapDataToProductOffer = (elem) => {
  return {
    functionalId: elem.functionalId,
    label: mapDataToInternationalisaion(elem.label),
    productOption: elem.productOption?.map((prodOpt) =>
      mapDataToProductOptionPair(prodOpt)
    ),
    optional: elem.optional,
  };
};
const mapDataToProductOptionPair = (data) => {
  return {
    product: data.product,
    options: data.options,
  };
};
