import PropTypes from "prop-types";
import ArrayAccordion from "src/components/generic/ArrayAccordion";
import { MODULE_DEFAULT } from "src/utils/defaultsSupplier";
import ModuleForm from "../ModuleForm";

export default function Module(props) {
  const { namePrefix } = props;

  return (
    <ArrayAccordion
      name={`${namePrefix}`}
      translationPrefix="modules"
      Form={ModuleForm}
      defaultValues={MODULE_DEFAULT}
      fieldsNames={["functionalId", "codeProvider", "label"]}
    />
  );
}

Module.propTypes = {
  namePrefix: PropTypes.string.isRequired,
};
