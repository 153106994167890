import PackagedProductSelect from "src/components/generic/inputs/PackagedProductMultiSelect/PackagedProductSelect";

const ProductOptionPairForm = (props) => {
  const { namePrefix } = props;

  return (
    <div className="mb-3">
      <PackagedProductSelect
        namePrefix={namePrefix}
        translationPrefix="productOption"
      />
    </div>
  );
};

export default ProductOptionPairForm;
