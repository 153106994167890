import { useState } from "react";
import { addProduct } from "src/api";
import ProductForm from "src/components/product/form/ProductForm";
import { toast, ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { mapDataToProduct } from "src/utils/mappers/productMapper/productMapper";
import { PRODUCT_BASE_PATH } from "src/routes/paths";

export const ProductAddPage = () => {
  const { state } = useLocation();

  const navigate = useNavigate();

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const onSubmit = async (data) => {
    setSubmitDisabled(true);
    try {
      const product = mapDataToProduct(data);
      await addProduct(product);
      navigate(PRODUCT_BASE_PATH, {
        state: {
          toastContent: "Product added successfully!",
        },
      });
    } catch (ex) {
      toast.error("there was an error, couldnt add product");
    } finally {
      setSubmitDisabled(false);
    }
  };
  return (
    <>
      <ProductForm
        {...{
          onSubmit,
          submitDisabled,
          versions: [],
        }}
        product={state?.product}
      />
      <ToastContainer />
    </>
  );
};
