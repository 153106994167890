import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ConstraintCommonFields from "src/components/constraint/form/ConstraintCommonFields";
import ConstraintSpecificFields from "src/components/constraint/form/ConstraintSpecificFields";
import SelectInput from "src/components/generic/inputs/SelectInput";
import { getNameWithNamePrefix } from "src/utils";

const ConstraintFormContent = (props) => {
  const { constraintTypes, namePrefix } = props;

  const { control } = useFormContext();
  const { t } = useTranslation();
  const type = useWatch({
    control,
    name: getNameWithNamePrefix("type", namePrefix),
  });

  return (
    <div className="grid grid-cols-1 gap-2 mb-4 mx-2 w-full">
      <SelectInput
        name={getNameWithNamePrefix("type", namePrefix)}
        label={t("constraints.constraintsType")}
        data={constraintTypes
          .map((c) => c.id)
          .sort((a, b) =>
            t(`constraints.${a}`).localeCompare(t(`constraints.${b}`))
          )
          .map((c) => ({ functionalId: c, name: t(`constraints.${c}`) }))}
      />
      <div className="col-span-2">
        <ConstraintCommonFields namePrefix={namePrefix} />
        <ConstraintSpecificFields
          namePrefix={namePrefix}
          constraintTypes={constraintTypes}
          type={constraintTypes.find((c) => c.id === type)}
        />
      </div>
    </div>
  );
};

export default ConstraintFormContent;
