import { useTranslation } from "react-i18next";
import CardDisplay from "src/components/generic/CardDisplay";
import ImageUpload from "src/components/generic/inputs/ImageUpload";

export default function Visuel() {
  const { t } = useTranslation();
  return (
    <CardDisplay title={t("visual.title")}>
      <ImageUpload name="imageLink" label="Image" />
    </CardDisplay>
  );
}
