export const mapDataToInternationalisaion = (input) => {
  const res = [];
  for (let locale in input) {
    res.push({ locale, content: input[locale] });
  }
  return res;
};

export const mapInternationalisationToData = (array) => {
  const res = {};
  array.forEach((elem) => (res[elem.locale] = elem.content));
  return res;
};

export const mapDataToTargets = (data) => {
  if (data.targetType === "FAMILY") return data["families"];
  if (data.targetType === "PRODUCT") return data["products"];
  if (data.targetType === "GROUP") return data["groups"];
  return [];
};

export const mapTargetsToData = (elem) => {
  if (elem.targetType === "FAMILY")
    return {
      families: elem.targets,
    };
  if (elem.targetType === "PRODUCT")
    return {
      products: elem.targets,
    };
  if (elem.targetType === "GROUP")
    return {
      groups: elem.targets,
    };
};
