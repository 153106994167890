import { useTranslation } from "react-i18next";
import CardDisplay from "src/components/generic/CardDisplay";
import ParameterMultiSelect from "src/components/generic/inputs/ParameterMultiSelect";

export default function Selling() {
  const { t } = useTranslation();
  return (
    <CardDisplay title={t("selling.title")}>
      <ParameterMultiSelect
        name="canals"
        domain="CANAL"
        label={t("selling.sellingCanals")}
        className="mb-3"
      />
      <ParameterMultiSelect
        name="sellingModes"
        domain="SELLING_MODE"
        label={t("selling.sellingModes")}
      />
    </CardDisplay>
  );
}
