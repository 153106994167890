import {
  FileOutlined,
  QrcodeOutlined,
  TagOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import InternalisationInput from "../../../generic/inputs/InternalisationInput";
import ParameterMultiSelect from "../../../generic/inputs/ParameterMultiSelect";
import SwitchInput from "../../../generic/inputs/SwitchInput";
import TextInput from "../../../generic/inputs/TextInput";

const SupportingDocumentForm = (props) => {
  const { namePrefix } = props;
  const { t } = useTranslation();
  return (
    <Row gutter={[16, 8]} className="mb-5">
      <Col span={12}>
        <TextInput
          name={`${namePrefix}.functionalId`}
          label={t("supportingDocuments.functionalId")}
          startAdornment={<QrcodeOutlined style={{ fontSize: "25px" }} />}
        />
      </Col>
      <Col span={12}>
        <InternalisationInput
          name={`${namePrefix}.label`}
          label={t("supportingDocuments.label")}
          startAdornment={<TagOutlined style={{ fontSize: "25px" }} />}
        />
      </Col>

      <Col span={12}>
        <TextInput
          name={`${namePrefix}.sides`}
          label={t("supportingDocuments.sides")}
          type="number"
          startAdornment={<FileOutlined style={{ fontSize: "25px" }} />}
        />
      </Col>
      <Col span={12}>
        <SwitchInput
          name={`${namePrefix}.mandatory`}
          label={t("supportingDocuments.mandatory")}
        />
      </Col>
      <Col span={24}>
        <ParameterMultiSelect
          className="col-span-2"
          name={`${namePrefix}.canals`}
          label={t("supportingDocuments.canals")}
          domain="CANAL"
          startAdornment={<ShopOutlined style={{ fontSize: "25px" }} />}
        />
      </Col>
    </Row>
  );
};

export default SupportingDocumentForm;
