import { useTranslation } from "react-i18next";

export const DashboardPage = () => {
  const { t } = useTranslation(null, { keyPrefix: "pages" });
  return (
    <div className="p-7 min-h-screen">
      <h1 className="capitalize text-xl font-bold">{t("welcome")}</h1>
      <div className="flex items-center justify-center">
        <p className="text-2xl m-20">{t("notImplemented")}</p>
      </div>
    </div>
  );
};
