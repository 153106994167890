import { Spin } from "antd";

export default function Loading() {
  return (
    <Spin
      tip="Chargement ..."
      className="absolute top-1/2 left-1/2"
      size="large"
    />
  );
}
