import HttpService from "../../services/HttpService";
import { PRODUCT_PATH } from "../apiPaths";

const instance = HttpService.getAxiosClient();

export const addProduct = async (product) => {
  const res = await instance.post(PRODUCT_PATH, product);
  return res.data;
};

export const updateProduct = async (product, id) => {
  const res = await instance.put(`${PRODUCT_PATH}${id}`, product);
  return res.data;
};

export const getAllProducts = async (page, size) => {
  const res = await instance.get(`${PRODUCT_PATH}?page=${page}&size=${size}`);

  return res.data;
};

export const getAllProductsByFamily = async (page, size, family) => {
  const res = await instance.get(
    `${PRODUCT_PATH}filtered?family=${family}&page=${page}&size=${size}`
  );
  return res.data;
};

export const getProductById = async (functionalId) => {
  const res = await instance.get(`${PRODUCT_PATH}${functionalId}`);
  return res.data;
};

export const getProductVersions = async (functionalId) => {
  const res = await instance.get(`${PRODUCT_PATH}${functionalId}/versions`);
  return res.data;
};

export const publishProductDraft = async (functionalId) => {
  const res = await instance.patch(`${PRODUCT_PATH}${functionalId}/publish`);
  return res.data;
};

export const unpublishProductDraft = async (functionalId) => {
  const res = await instance.patch(`${PRODUCT_PATH}${functionalId}/unpublish`);
  return res.data;
};

export const createProductDraft = async (functionalId) => {
  const res = await instance.put(`${PRODUCT_PATH}${functionalId}/draft`);
  return res.data;
};

export const getAllModules = async () => {
  const res = await instance.get(`${PRODUCT_PATH}modules`);
  return res.data;
};

export const getAllProductsCodes = async (family = "") => {
  const res = await instance.get(
    `${PRODUCT_PATH}product-codes?family=${family}`
  );
  return res.data;
};
export const getAllPackagedProducts = async () => {
  const res = await instance.get(`${PRODUCT_PATH}packaged?sellingMode=PCK`);
  return res.data;
};
