import {
  DollarCircleOutlined,
  QrcodeOutlined,
  TagOutlined,
} from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import TextInput from "../../../../generic/inputs/TextInput";

const OptionDetailsForm = (props) => {
  const { namePrefix } = props;
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 12]} className="mb-2">
      <Col span={12}>
        <TextInput
          name={`${namePrefix}.functionalId`}
          label={t("optionDetails.functionalId")}
          type="text"
          startAdornment={<QrcodeOutlined style={{ fontSize: "25px" }} />}
        />
      </Col>
      <Col span={12}>
        <TextInput
          name={`${namePrefix}.label`}
          label={t("optionDetails.label")}
          type="text"
          startAdornment={<TagOutlined style={{ fontSize: "25px" }} />}
        />
      </Col>
      <Col span={24}>
        <TextInput
          name={`${namePrefix}.plafond`}
          label={t("optionDetails.plafond")}
          type="number"
          className="col-span-2"
          startAdornment={<DollarCircleOutlined style={{ fontSize: "25px" }} />}
        />
      </Col>
    </Row>
  );
};

export default OptionDetailsForm;
