import { PlusOutlined } from "@ant-design/icons";
import { Button, Tag } from "antd";
import PropTypes from "prop-types";
import { useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ComponentDisabler from "src/components/generic/ComponentDisabler";
import useModal from "../../../../hooks/componentHooks/useModal";

import ConstraintAddForm from "../ConstraintAddForm/ConstraintAddForm";

export default function ConstraintsAdd(props) {
  const { className, namePrefix } = props;
  const name = namePrefix ? namePrefix + ".constraints" : "constraints";

  const [constraintIndex, setConstraintIndex] = useState();
  const { Modal, setOpen } = useModal();
  const { control, setValue } = useFormContext();
  const { append, remove } = useFieldArray({
    control,
    name,
  });
  const constraints =
    useWatch({
      control,
      name,
    }) || [];

  const { t } = useTranslation();

  return (
    <div className={className}>
      {constraints.map((con, index) => {
        return (
          <Tag
            className="cursor-pointer"
            color="processing"
            onClick={() => {
              setConstraintIndex(index);
              setOpen(true);
            }}
            closable
            onClose={(e) => {
              e.preventDefault();
              remove(index);
            }}
            key={con.id}
          >
            {constraints[index].label}
          </Tag>
        );
      })}
      <div>
        <ComponentDisabler
          componentRender={() => (
            <Button
              shape="circle"
              className="flex justify-center items-center my-2"
              onClick={() => {
                setConstraintIndex(-1);
                setOpen(true);
              }}
              icon={<PlusOutlined />}
            />
          )}
        />
      </div>
      <Modal
        widthFull
        
        title={
          constraintIndex !== -1
            ? "Modification de la condition"
            : t("constraints.titleModal")
        }
      >
        <ConstraintAddForm
          constraint={constraints[constraintIndex]}
          onCancel={() => setOpen(false)}
          addConstraint={(con) => {
            append(con);
            setOpen(false);
          }}
          updateConstraint={(con) => {
            setValue(`${name}[${constraintIndex}]`, con);
            setOpen(false);
          }}
        />
      </Modal>
    </div>
  );
}

ConstraintsAdd.propTypes = {
  className: PropTypes.string,
};
