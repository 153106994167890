import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addEligibleAct } from "src/api/eligibleAct";
import EligibleActForm from "src/components/eligibleAct/form/EligibleActForm";
import CardDisplay from "src/components/generic/CardDisplay";
import { ELIGIBLE_ACT_BASE_PATH } from "src/routes/paths";
import { mapDataToEligibleAct } from "src/utils/mappers/eligibleActMapper";

function EligibleActAddPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const onSubmit = async (data) => {
    try {
      setSubmitDisabled(true);
      const mappedEligibleAct = mapDataToEligibleAct(data);
      await addEligibleAct(mappedEligibleAct);
      navigate(ELIGIBLE_ACT_BASE_PATH, {
        state: {
          toastContent: "Eligible Act updated successfully!",
        },
      });
    } catch (err) {
      toast.error("There was an error, couldn't add Eligible Act");
    } finally {
      setSubmitDisabled(false);
    }
  };
  return (
    <div className="p-3">
      <CardDisplay titleCentered title={t("eligibleAct.add")}>
        <EligibleActForm submitDisabled={submitDisabled} onSubmit={onSubmit} />
      </CardDisplay>
    </div>
  );
}

export default EligibleActAddPage;
