import {
  mapDataToInternationalisaion,
  mapInternationalisationToData,
} from "src/utils/mappers/mapperHelpers";

export const mapDataToExemption = (data) => {
  const options = (data.options || []).map((elem) =>
    mapDataToExemptionOption(elem)
  );
  return {
    options,
    type: "exemption",
  };
};

export const mapExemptionToData = (exemption) => {
  return {
    options: (exemption.options || []).map((option) =>
      mapExemptionOptionToData(option)
    ),
  };
};

const mapDataToExemptionOption = (data) => {
  return {
    functionalId: data.functionalId,
    label: mapDataToInternationalisaion(data.label),
    description: mapDataToInternationalisaion(data.description),
  };
};

const mapExemptionOptionToData = (elem) => {
  return {
    functionalId: elem.functionalId,
    label: mapInternationalisationToData(elem.label),
    description: mapInternationalisationToData(elem.description),
  };
};
