import { CONSTRAINT_PATH } from "src/api/apiPaths";
import HttpService from "../../services/HttpService";

const instance = HttpService.getAxiosClient();

export const getConstraintTypes = async () => {
  const res = await instance.get(`${CONSTRAINT_PATH}types`);
  return res.data;
};

export const getGenericConstraintOperations = async () => {
  const res = await instance.get(`${CONSTRAINT_PATH}operations`);
  return res.data;
};
