import { TranslationOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import useModal from "src/hooks/componentHooks/useModal";

import { fetchSupportedLanguages } from "src/redux/api/supportedLanguages";
import TextInput from "../TextInput";

export default function InternalisationInput(props) {
  const { name, label, startAdornment } = props;
  const { Modal, setOpen } = useModal();

  const dispatch = useDispatch();
  const languages = useSelector(
    (state) => state?.supportedLanguages?.supportedLanguages
  );
  const selectedLang = useSelector(
    (state) => state.selectedLanguage.selectedLanguage
  );

  useEffect(() => {
    if (languages.length === 0) {
      dispatch(fetchSupportedLanguages());
    }
  }, [dispatch, languages.length]);

  const { control } = useFormContext();

  return (
    <>
      <TextInput
        key={name + `[${selectedLang}]`}
        name={name + `[${selectedLang}]`}
        label={label}
        endAdornment={
          <TranslationOutlined
            style={{ fontSize: "25px" }}
            onClick={() => setOpen(true)}
          />
        }
        startAdornment={startAdornment}
      />
      <Modal title={"Internationalisation of " + label}>
        <>
          {languages
            .filter((lan) => lan.functionalId !== selectedLang)
            .map((lan) => (
              <TextInput
                key={`${name}[${lan.functionalId}]`}
                name={`${name}[${lan.functionalId}]`}
                control={control}
                label={label + " in " + lan.name}
              />
            ))}
        </>
      </Modal>
    </>
  );
}

InternalisationInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  required: PropTypes.bool,
};
