import {
  mapDataToInternationalisaion,
  mapInternationalisationToData,
} from "../../mapperHelpers";

export const mapBancassToData = (bancass) => {
  return {
    options: bancass.options.map((option) => mapOptionsToData(option)),
  };
};

const mapOptionsToData = (elem) => {
  return {
    functionalId: elem.functionalId,
    label: mapInternationalisationToData(elem.label),
    optionDetails: elem.optionDetails,
  };
};

export const mapDataToBancass = (data) => {
  const options = data.options.map((elem) => mapDataToOptions(elem));
  return {
    options,
    type: "bancass",
  };
};

const mapDataToOptions = (elem) => {
  return {
    functionalId: elem.functionalId,
    label: mapDataToInternationalisaion(elem.label),
    optionDetails: elem.optionDetails,
  };
};
