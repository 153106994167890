import { RECOMMENDATION_PATH } from "src/api/apiPaths";
import HttpService from "../../services/HttpService";

const instance = HttpService.getAxiosClient();

export const addRecommendation = async (recommendation) => {
  const res = await instance.post(RECOMMENDATION_PATH, recommendation);
  return res.data;
};

export const getAllRecommendations = async (page, size) => {
  const res = await instance.get(
    `${RECOMMENDATION_PATH}?page=${page}&size=${size}`
  );
  return res.data;
};

export const getRecommendationById = async (recommendationId) => {
  const res = await instance.get(`${RECOMMENDATION_PATH}${recommendationId}`);
  return res.data;
};

export const updateRecommendation = async (recommendation, id) => {
  const res = await instance.put(`${RECOMMENDATION_PATH}${id}`, recommendation);
  return res.data;
};

export const getRecommendationVersions = async (functionalId) => {
  const res = await instance.get(
    `${RECOMMENDATION_PATH}${functionalId}/versions`
  );
  return res.data;
};

export const publishRecommendationDraft = async (functionalId) => {
  const res = await instance.patch(
    `${RECOMMENDATION_PATH}${functionalId}/publish`
  );
  return res.data;
};

export const unpublishRecommendationDraft = async (functionalId) => {
  const res = await instance.patch(
    `${RECOMMENDATION_PATH}${functionalId}/unpublish`
  );
  return res.data;
};

export const createRecommendationDraft = async (functionalId) => {
  const res = await instance.put(`${RECOMMENDATION_PATH}${functionalId}/draft`);
  return res.data;
};
