import { useQuery } from "react-query";
import { getParametersByDomain } from "../../../api";

const getParameters = async ({ queryKey }) => {
  const domain = queryKey[1];
  return getParametersByDomain(domain);
};

export default function useParameters(domain) {
  return useQuery(["parametersByDomain", domain], getParameters);
}
