import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CardDisplay from "src/components/generic/CardDisplay";

export default function ParameterCard(props) {
  const { parameter } = props;
  const { t } = useTranslation(null, { keyPrefix: "confirmation" });
  const selectedLang = useSelector(
    (state) => state.selectedLanguage.selectedLanguage
  );

  return (
    <CardDisplay title={parameter.name} className="h-full">
      <div>
        <span className="text-gray-500 font-light">Code: </span>
        <span>{parameter.functionalId}</span>
      </div>
      <div>
        <span className="text-gray-500 font-light">Description: </span>
        <span>
          {
            parameter.descriptions.filter(
              (desc) => desc.locale === selectedLang
            )[0]?.content
          }
        </span>
      </div>

      <div>
        <span className="text-gray-500 font-light">{t("active")}: </span>

        <span>{parameter.active ? t("yes") : t("no")}</span>
      </div>
    </CardDisplay>
  );
}

ParameterCard.propTypes = {
  parameter: PropTypes.object.isRequired,
};
