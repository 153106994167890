import { DatePicker, Form } from "antd";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

export default function CustomDatePicker(props) {
  const { label, name, placeholder } = props;

  const { control, formDisabled } = useFormContext();

  return (
    <Form.Item style={{ marginBottom: "0px" }}>
      <label>{label}</label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <DatePicker
            {...field}
            size="large"
            className="w-full"
            placeholder={placeholder}
            disabled={formDisabled}
          />
        )}
      />
    </Form.Item>
  );
}

DatePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  control: PropTypes.object,
  errors: PropTypes.object,
};
