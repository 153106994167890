import {
  DollarCircleOutlined,
  HourglassOutlined,
  PoundCircleOutlined,
  SlidersOutlined,
  TransactionOutlined,
} from "@ant-design/icons";
import { Col, Row } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import ParameterSelect from "src/components/generic/inputs/ParameterSelect";
import SwitchInput from "src/components/generic/inputs/SwitchInput";
import TextInput from "src/components/generic/inputs/TextInput";

const PlafondForm = (props) => {
  const { namePrefix } = props;
  const { t } = useTranslation();
  return (
    <Row gutter={[16, 12]} className="m-1 mb-5">
      <Col span={12}>
        <ParameterSelect
          name={`${namePrefix}.operation`}
          label={t("plafondForm.operation")}
          domain="OPERATION"
          startAdornment={<TransactionOutlined style={{ fontSize: "25px" }} />}
        />
      </Col>

      <Col span={12}>
        <ParameterSelect
          name={`${namePrefix}.devise`}
          label={t("plafondForm.devise")}
          domain="CURRENCY"
          startAdornment={<PoundCircleOutlined style={{ fontSize: "25px" }} />}
        />
      </Col>

      <Col span={12}>
        <ParameterSelect
          name={`${namePrefix}.periodicity`}
          label={t("plafondForm.periodicity")}
          domain="PERIODICITY"
          startAdornment={<HourglassOutlined style={{ fontSize: "25px" }} />}
        />
      </Col>

      <Col span={12}>
        <TextInput
          name={`${namePrefix}.amount`}
          label={t("plafondForm.amount")}
          type="number"
          startAdornment={<DollarCircleOutlined style={{ fontSize: "25px" }} />}
        />
      </Col>

      <Col span={12}>
        <TextInput
          name={`${namePrefix}.amountMin`}
          label={t("plafondForm.amountMin")}
          type="number"
          startAdornment={<DollarCircleOutlined style={{ fontSize: "25px" }} />}
        />
      </Col>

      <Col span={12}>
        <TextInput
          name={`${namePrefix}.amountMax`}
          label={t("plafondForm.amountMax")}
          type="number"
          startAdornment={<DollarCircleOutlined style={{ fontSize: "25px" }} />}
        />
      </Col>

      <Col span={12}>
        <TextInput
          name={`${namePrefix}.step`}
          label={t("plafondForm.step")}
          type="number"
          className="col-span-2"
          startAdornment={<SlidersOutlined style={{ fontSize: "25px" }} />}
        />
      </Col>

      <Col span={12}>
        <SwitchInput
          name={`${namePrefix}.stateChangeable`}
          label={t("plafondForm.stateChangeable")}
        />
      </Col>

      <Col span={12}>
        <SwitchInput
          name={`${namePrefix}.ceilingChangeable`}
          label={t("plafondForm.cellingChangeable")}
        />
      </Col>

      <Col span={12}>
        <SwitchInput name={`${namePrefix}.consultable`} label="Consultable" />
      </Col>
    </Row>
  );
};
PlafondForm.propTypes = {
  namePrefix: PropTypes.string.isRequired,
};

export default PlafondForm;
