import { Form, Input } from "antd";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

export default function TextInput(props) {
  const {
    name,
    label,
    className,
    startAdornment,
    endAdornment,
    placeholder,
    disabled,
  } = props;

  const { control, formDisabled } = useFormContext();

  return (
    <Form.Item style={{ marginBottom: "0px" }}>
      <label>{label}</label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            size="large"
            className={className}
            placeholder={placeholder}
            addonBefore={
              startAdornment ? (
                <div className="flex justify-center items-center">
                  {startAdornment}
                </div>
              ) : null
            }
            addonAfter={endAdornment}
            disabled={disabled || formDisabled}
          />
        )}
      />
    </Form.Item>
  );
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(["text", "number", "password"]),
};

TextInput.defaultValues = {
  type: "text",
};
