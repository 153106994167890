import { CreditCardOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import CardDisplay from "src/components/generic/CardDisplay";
import ParameterSelect from "src/components/generic/inputs/ParameterSelect";
import AdditionalAttribute from "../../generic/AdditionalAttribute";
import Theme from "../Theme/Theme";

const BankCard = () => {
  const { t } = useTranslation();
  return (
    <CardDisplay title={t("displayCard.cardInfo")}>
      <div className="grid grid-cols-2 gap-4">
        <ParameterSelect
          name="debitType"
          label={t("cardBank.debitType")}
          domain="DEBIT_TYPE"
          startAdornment={<CreditCardOutlined style={{ fontSize: "25px" }} />}
          sx={{ margin: "7px" }}
        />
      </div>
      <Theme />
      <AdditionalAttribute />
    </CardDisplay>
  );
};

export default BankCard;
