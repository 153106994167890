import PropTypes from "prop-types";
import { useState } from "react";
import { useQuery } from "react-query";
import ConstraintFormContent from "src/components/constraint/form/ConstraintFormContent/ConstraintFormContent";
import Form from "src/components/generic/Form";
import Loading from "src/components/generic/Loading";
import { CONSTRAINT_DEFAULT } from "src/utils/defaultsSupplier";
import { CONSTRAINT_VALIDATION_SCHEMA } from "src/utils/validationSchemas/constraintSchema";
import { getConstraintTypes } from "../../../../api";

export default function ConstraintAddForm(props) {
  const { addConstraint, updateConstraint, onCancel, constraint } = props;

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const { data: constraintTypes, isLoading } = useQuery(
    "constraint-types",
    getConstraintTypes
  );

  const onSubmit = (data) => {
    setSubmitDisabled(true);
    if (!constraint) addConstraint(data);
    else updateConstraint(data);
    setSubmitDisabled(false);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <Form
      onCancel={onCancel}
      onSubmit={onSubmit}
      defaultValues={
        constraint
          ? constraint
          : { ...CONSTRAINT_DEFAULT, type: constraintTypes[0].id }
      }
      submitDisabled={submitDisabled}
      validationSchema={CONSTRAINT_VALIDATION_SCHEMA}
    >
      <ConstraintFormContent constraintTypes={constraintTypes} />
    </Form>
  );
}

ConstraintAddForm.propTypes = {
  addConstraint: PropTypes.func,
  targetType: PropTypes.string,
};
