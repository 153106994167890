import React from "react";
import { OPTIONS_DEFAULT } from "../../../../../utils/defaultsSupplier";
import ArrayAccordion from "../../../../generic/ArrayAccordion";
import OptionsForm from "../../optionForm";

const Option = () => {
  return (
    <ArrayAccordion
      name="options"
      translationPrefix="options"
      Form={OptionsForm}
      defaultValues={OPTIONS_DEFAULT}
      fieldsNames={["functionalId", "label"]}
    />
  );
};

export default Option;
