import { mapProductToData } from "src/utils";
import { PRODUCT_DEFAULT } from "src/utils/defaultsSupplier";
import { PRODUCT_VALIDATION_SCHEMA } from "src/utils/validationSchemas/productSchema";
import CardDisplay from "../../../generic/CardDisplay";
import Form from "../../../generic/Form";
import MainInfo from "../generic/MainInfo";
import Selling from "../generic/Selling";
import Status from "../generic/Status";
import VersionsTimeLine from "../generic/VersionsTimeLine";
import Visuel from "../generic/Visuel";
import SpecificInfo from "../generic/SpecificInfo";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import { PRODUCT_BASE_PATH } from "src/routes/paths";
import useVersions from "src/hooks/normalHooks/useVersions";
import { getProductVersions } from "src/api";
import Loading from "src/components/generic/Loading";
import { Error500 } from "src/pages/error";

export default function ProductForm(props) {
  const {
    product,
    onSubmit,
    submitDisabled,
    isUpdateForm,
    formDisabled,
    additionalButtons,
  } = props;

  const navigate = useNavigate();

  const { versions, isLoading, isError } = useVersions(
    product?.id,
    getProductVersions
  );

  if (isLoading) return <Loading />;
  if (isError) return <Error500 />;

  return (
    <Form
      submitDisabled={submitDisabled}
      onSubmit={onSubmit}
      formDisabled={formDisabled}
      isUpdateForm={isUpdateForm}
      onCancel={() => navigate(PRODUCT_BASE_PATH)}
      defaultValues={product ? mapProductToData(product) : PRODUCT_DEFAULT}
      validationSchema={PRODUCT_VALIDATION_SCHEMA}
      buttonsOnTop={true}
      additionalButtons={additionalButtons}
    >
      <Row gutter={[16, 12]} className="px-3 mt-5">
        <Col span={8}>
          <Visuel />
          <CardDisplay title="Versions">
            <VersionsTimeLine versions={versions} path="/products/update/" />
          </CardDisplay>
          <Status />
          <Selling />
        </Col>
        <Col span={16}>
          <MainInfo />
          <SpecificInfo />
        </Col>
      </Row>
    </Form>
  );
}
