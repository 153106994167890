import { mapDateToData } from "../dateMapper";
import {
  mapDataToInternationalisaion,
  mapInternationalisationToData,
} from "../mapperHelpers";

export const mapDiscountsToData = (discounts) => {
  return discounts.map((elem) => ({
    functionalId: elem.discountId,
    dateFrom: mapDateToData(elem.dateFrom),
    dateTo: mapDateToData(elem.dateTo),
    active: elem.active,
    rate: elem.rate,
    description: mapInternationalisationToData(elem.description),
    constraints: elem.constraints,
  }));
};

export const mapDataToDiscounts = (discounts) => {
  return discounts.map((elem) => ({
    discountId: elem.functionalId,
    dateFrom: elem.dateFrom,
    dateTo: elem.dateTo,
    active: !!elem.active,
    rate: elem.rate,
    description: mapDataToInternationalisaion(elem.description),
    constraints: elem.constraints,
  }));
};
