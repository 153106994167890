import React from "react";
import { useTranslation } from "react-i18next";
import CardDisplay from "../CardDisplay";

const EventCard = ({ event }) => {
  const { t } = useTranslation();
  return (
    <CardDisplay className="h-full" title={event.functionalId}>
      {event.act && (
        <div>
          <span className="text-gray-500 font-light">
            {t("eventCard.act")} :
          </span>
          <span>{event.act}</span>
        </div>
      )}
      <div>
        <span className="text-gray-500 font-light">
          {t("eventCard.targetType")} :
        </span>
        <span>{event.targetType}</span>
      </div>
      <div>
        <span className="text-gray-500 font-light">
          {t("eventCard.targets")} :
        </span>
        <span>
          {event.targetType !== "ALL"
            ? event.targets.join(", ")
            : t("targets.all")}
        </span>
      </div>
    </CardDisplay>
  );
};

export default EventCard;
