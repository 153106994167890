import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { getAllProducts, getAllProductsByFamily } from "src/api";
import CustomPagination from "src/components/generic/CustomPagination";
import Loading from "src/components/generic/Loading";
import ProductCard from "src/components/product/listing/ProductCard";
import ProductFilter from "src/components/product/listing/ProductFilter";
import { PRODUCT_ADD_PATH } from "src/routes/paths";
import { Error500 } from "../../error";

const ELEMENT_PER_PAGE = 21;

export const ProductListingPage = () => {
  const [page, setPage] = useState(1);
  const [currentFilter, setCurrentFilter] = useState("all");
  const { t } = useTranslation();
  const { state } = useLocation();

  const { data, isLoading, isError } = useQuery(
    ["get-products", page, currentFilter],
    () => {
      if (currentFilter === "all")
        return getAllProducts(page - 1, ELEMENT_PER_PAGE);
      return getAllProductsByFamily(page - 1, ELEMENT_PER_PAGE, currentFilter);
    }
  );

  useEffect(() => {
    if (state?.toastContent) toast.success(state?.toastContent);
  }, [state]);

  const onFilter = (param) => {
    setCurrentFilter(param);
    setPage(1);
  };
  if (isLoading) return <Loading />;
  if (isError) return <Error500 />;

  return (
    <>
      <ToastContainer />
      <div className="w-full h-full flex flex-col p-5">
        <div className="flex justify-between items-center mb-3 ">
          <ProductFilter onFilter={onFilter} currentFilter={currentFilter} />
          <Link to={PRODUCT_ADD_PATH}>
            <Button
              type="primary"
              className="bg-orange-500 text-white flex items-center"
            >
              <PlusOutlined sx={{ fontSize: "20px" }} /> {t("crud.add")}{" "}
              {t("subnav.product")}
            </Button>
          </Link>
        </div>
        <div className="grid grid-cols-7 gap-4 w-full mb-2">
          {data.content.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>

        <CustomPagination
          current={page}
          total={data.totalElements}
          pageSize={ELEMENT_PER_PAGE}
          onChange={(value) => setPage(value)}
        />
      </div>
    </>
  );
};
export default ProductListingPage;
