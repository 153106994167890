import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { t } from "i18next";
import ComponentDisabler from "../ComponentDisabler";
import { Button } from "antd";
import modalConfirm from "src/utils/modalConfirm";

export default function Form(props) {
  const {
    children,
    onSubmit,
    submitDisabled,
    formDisabled,
    isUpdateForm,
    defaultValues,
    validationSchema,
    onCancel,
    buttonsOnTop,
    additionalButtons,
  } = props;

  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit } = formMethods;

  const submit = (e) => {
    handleSubmit(onSubmit)(e);
    e.stopPropagation();
  };

  return (
    <form onSubmit={submit} className="p-5">
      <FormProvider {...{ ...formMethods, formDisabled, isUpdateForm }}>
        {buttonsOnTop ? null : children}
        <div className="flex justify-end px-3">
          {additionalButtons}
          <Button
            danger
            className="mr-5"
            onClick={() => {
              modalConfirm(
                "Confirmez-vous l'annulation de votre saisie?",
                onCancel
              );
            }}
          >
            {t("submit.cancel")}
          </Button>
          <ComponentDisabler
            componentRender={() => (
              <Button
                type="primary"
                ghost
                disabled={submitDisabled}
                htmlType="submit"
                className="border-green-600 text-green-600 hover:border-green-500 hover:text-green-500 disabled:opacity-30"
              >
                {t("submit.submit")}
              </Button>
            )}
          />
        </div>
        {buttonsOnTop ? children : null}
      </FormProvider>
    </form>
  );
}

Form.propTypes = {
  children: PropTypes.any,
  onSubmit: PropTypes.func,
  defaultValues: PropTypes.object,
  validationSchema: PropTypes.object,
};
