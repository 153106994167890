import i18n from "src/i18n";
import * as yup from "yup";

const REQUIRED_STRING = yup.string().required(i18n.t("validator.blank"));

export const ELIGIBLE_ACT_VALIDATION_SCHEMA = yup
  .object({
    functionalId: REQUIRED_STRING,
  })
  .required();
