export const mapPricesToData = (prices) => {
  return prices.map((elem) => ({
    functionalId: elem.functionalId,
    type: elem.type,
    price: elem.price,
    min: elem.min,
    max: elem.max,
    flexibility: elem.flexibility,
    currency: elem.currency,
    constraints: elem.constraints,
  }));
};

export const mapDataToPrices = (prices, isStandard) => {
  return prices.map((elem) => ({
    functionalId: elem.functionalId,
    type: elem.type,
    price: elem.price,
    standard: isStandard,
    min: elem.min,
    max: elem.max,
    flexibility: elem.flexibility,
    currency: elem.currency,
    constraints: elem.constraints,
  }));
};
