export const PLAFOND_DEFAULT = {
  functionalId: "",
  labelOperation: "",
  deviseOperation: "",
  codeOrigin: "",
  labelOrigin: "",
  codePeriodicity: "",
  periodicity: "M",
  startDate: "",
  endDate: "",
  amount: 0,
  amountMin: 0,
  amountMax: 0,
  step: 0,
  cap: 0,
  capSharingCodeOper: "",
  plafondType: "",
  stateChangeable: false,
  ceilingChangeable: false,
  consultable: false,
  foreignService: false,
};

export const FORMULA_DETAIL_DEFAULT = {
  functionalId: "",
  productType: "",
  label: "",
  offer: "",
  providerCode: "",
  pricePeriodic: 0,
  optional: false,
  toLink: false,
  linkInPostSell: false,
  hasProductSheet: false,
  isMoneyFacility: false,
};

export const FORMULA_DEFAULT = {
  formulaCode: "",
  label: "",
  plafonds: [],
  formulaDetails: [],
};

export const THEME_DEFAULT = {
  thumbnail: "",
  largeImage: "",
  functionalId: "",
  themeDescription: "",
  themeLabel: "",
  themeDetails: [],
};

export const BANCASS_DEFAULT = {
  options: [],
};

export const CARD_DEFAULT = {
  debitType: "",
  themes: [],
};

export const PRODUCT_DEFAULT = {
  functionalId: "",
  name: "",
  description: "",
  family: "",
  provider: "",
  groups: [],
  imageLink: "",
  canals: [],
  sellingModes: [],
  active: false,
  activationStartDate: "",
  activationEndDate: "",
  standardPricing: [],
  preferentialPricing: [],
  discounts: [],
  eligibleCards: [],
  renewable: false,
  additionalAttributes: [],
};

export const PARAMETER_DEFAULT = {
  functionalId: "",
  name: "",
  description: "",
  active: false,
};

export const CAP_DEFAULT = {
  plafondIds: [],
  amount: 0,
};

export const CONSTRAINT_DEFAULT = {
  label: "",
  description: "",
  minAge: 0,
  maxAge: 0,
  minBalance: 0,
  clientCategoryList: [],
  clientSegmentList: [],
  clientTypeList: [],
  accountTypeList: [],
  bilanList: [],
  canals: [],
  contractPhases: [],
  contractStatus: [],
  families: [],
  maxAllowed: 0,
  flag: "",
  minFluxExport: 0,
  markets: [],
  moduleList: [],
  products: [],
  sexe: "",
};

export const OPTIONS_DEFAULT = {
  code: "",
  label: [],
  optionDetails: [],
};
export const OPTION_DETAILS_DEFAULT = {
  code: "",
  label: "",
  plafond: "",
};

export const ELIGIBLE_ACT_DEFAULT = {
  functionalId: "",
  products: [],
  families: [],
  groups: [],
  consents: [],
  supportingDocuments: [],
};
export const BANK_ONLINE_DEFAULT = {
  offers: [],
};

export const OFFER_DEFAULT = {
  functionalId: "",
  codeOfferTech: "",
  label: [],
  description: [],
  modules: [],
};

export const MODULE_DEFAULT = {
  functionalId: "",
  codeProvider: "",
  label: [],
  optional: false,
  options: [],
};

export const MODULE_OPTION_DEFAULT = {
  description: {},
  additionalPrice: 0,
  periodicity: "M",
  remarks: [],
};

export const ADDITIONAL_ATTRIBUTE_DEFAULT = {
  name: "",
  type: "Text",
  value: {
    Text: "",
    Number: 0,
    ListNumber: [],
    ListText: [],
  },
};
export const SUPPORTING_DOCUMENT_DEFAULT = {
  functionalId: "",
  label: "",
  sides: [],
  canals: [],
  mandatory: false,
};

export const CONSENT_DEFAULT = {
  functionalId: "",
  content: "",
  canals: [],
};

export const EVENT_PRICING_DEFAULT = {
  functionalId: "",
  products: [],
  families: [],
  groups: [],
  commissions: [],
};

export const PRICING_DEFAULT = {
  type: "FLAT",
  price: 0,
  currency: null,
};

export const BILAN_DEFAULT = {
  functionalId: "",
};

export const SERIALS_DEFAULT = {
  minSerial: "",
  maxSerial: "",
};
export const PRODUCT_OFFER_DEFAULT = {
  functionalId: "",
  label: {},
  products: [],
  optional: false,
};
export const DISCOUNT_DEFAULT = {
  rate: 0,
  active: false,
  dateFrom: null,
  dateTo: null,
};

export const COMMISSION_DEFAULT = {
  functionalId: "",
  label: {
    fr_FR: "",
    en_US: "",
  },
  standardPricing: [],
  preferentialPricing: [],
  discountList: [],
};

export const RECOMMENDATION_DEFAULT = {
  functionalId: "",
  description: [],
  targetType: {},
  products: [],
  families: [],
  groups: [],
  constraints: [],
};

export const EXEMPTIONOPTION_DEFAULT = {
  functionalId: "",
  label: [],
  description: [],
};
