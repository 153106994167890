import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  createEligibleActDraft,
  getEligibleActById,
  publishEligibleActDraft,
  unpublishEligibleActDraft,
  updateEligibleAct,
} from "src/api/eligibleAct";
import EligibleActForm from "src/components/eligibleAct/form/EligibleActForm";
import CardDisplay from "src/components/generic/CardDisplay";
import Loading from "src/components/generic/Loading";
import VersioningButtons from "src/components/generic/VersioningButtons";
import { Error500 } from "src/pages/error";
import { ELIGIBLE_ACT_BASE_PATH } from "src/routes/paths";
import { mapDataToEligibleAct } from "src/utils/mappers/eligibleActMapper";

export default function EligibleActUpdatePage() {
  const { eligibleActId } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    data: eligibleAct,
    isLoading,
    isError,
  } = useQuery(["get-eligible-act", eligibleActId], () =>
    getEligibleActById(eligibleActId)
  );
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const { t } = useTranslation();

  const onSubmit = async (data) => {
    try {
      setSubmitDisabled(true);
      const mappedEligibleAct = mapDataToEligibleAct(data);
      await updateEligibleAct(mappedEligibleAct, eligibleActId);
      queryClient.invalidateQueries({ queryKey: ["get-eligible-act"] });
      navigate(ELIGIBLE_ACT_BASE_PATH, {
        state: {
          toastContent: "Eligible Act added successfully!",
        },
      });
    } catch (err) {
      toast.error("There was an error, couldn't update Eligible Act");
    } finally {
      setSubmitDisabled(false);
    }
  };
  if (isLoading) return <Loading />;
  if (isError) return <Error500 />;
  return (
    <div className="p-3">
      <CardDisplay titleCentered title={t("eligibleAct.update")}>
        <EligibleActForm
          submitDisabled={submitDisabled}
          onSubmit={onSubmit}
          formDisabled={eligibleAct.version !== "draft"}
          eligibleAct={eligibleAct}
          isUpdateForm={true}
          additionalButtons={
            <VersioningButtons
              element={eligibleAct}
              createDraft={createEligibleActDraft}
              publishDraft={publishEligibleActDraft}
              unpublishDraft={unpublishEligibleActDraft}
              updateUrl="/eligible-acts/update/"
            />
          }
        />
      </CardDisplay>
    </div>
  );
}
