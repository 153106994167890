export const APP_BASE_PATH = "/";
export const DASHBOARD_BASE_PATH = "/dashboard";
export const PRODUCT_BASE_PATH = "/products";
export const PRODUCT_ADD_PATH = PRODUCT_BASE_PATH + "/new";
export const PRODUCT_UPDATE_PATH = PRODUCT_BASE_PATH + "/update/:functionalId";
export const ELIGIBLE_ACT_BASE_PATH = "/eligible-acts";
export const ELIGIBLE_ACT_ADD_PATH = ELIGIBLE_ACT_BASE_PATH + "/new";
export const ELIGIBLE_ACT_UPDATE_PATH =
  ELIGIBLE_ACT_BASE_PATH + "/update/:eligibleActId";
export const PARAMETER_BASE_PATH = "/parameters";
export const USERS_BASE_PATH = "/users";

export const HOME_BASE_PATH = DASHBOARD_BASE_PATH;

export const EVENT_PRICING_BASE_PATH = "/event-pricings";
export const EVENT_PRICING_ADD_PATH = EVENT_PRICING_BASE_PATH + "/new";
export const EVENT_PRICING_UPDATE_PATH =
  EVENT_PRICING_BASE_PATH + "/update/:eventPricingId";

export const RECOMMENDATION_BASE_PATH = "/recommendations";
export const RECOMMENDATION_ADD_PATH = RECOMMENDATION_BASE_PATH + "/new";
export const RECOMMENDATION_UPDATE_PATH =
  RECOMMENDATION_BASE_PATH + "/update/:recommendationId";
export const ERROR_PATH = "/error";
