import {
  DASHBOARD_BASE_PATH,
  PRODUCT_BASE_PATH,
  USERS_BASE_PATH,
  PARAMETER_BASE_PATH,
  PRODUCT_ADD_PATH,
  PRODUCT_UPDATE_PATH,
  ELIGIBLE_ACT_ADD_PATH,
  ELIGIBLE_ACT_UPDATE_PATH,
  ELIGIBLE_ACT_BASE_PATH,
  EVENT_PRICING_ADD_PATH,
  EVENT_PRICING_BASE_PATH,
  EVENT_PRICING_UPDATE_PATH,
  ERROR_PATH,
  RECOMMENDATION_ADD_PATH,
  RECOMMENDATION_BASE_PATH,
  RECOMMENDATION_UPDATE_PATH,
} from "./paths";
import {
  DashboardPage,
  UsersPage,
  ParameterListingPage,
  ProductListingPage,
  ProductAddPage,
} from "src/pages";
import { ProductUpdatePage } from "src/pages/products/ProductUpdatePage";
import EligibleActUpdatePage from "src/pages/eligibleActs/EligibleActUpdatePage";
import EligibleActsListingPage from "../pages/eligibleActs/EligibleActsListingPage";
import EventPricingAddPage from "../pages/eventPricing/eventPricingAddPage";
import EventPricingListingPage from "../pages/eventPricing/eventPricingListingPage";
import EventPricingUpdatePage from "../pages/eventPricing/eventPricingUpdatePage";
import EligibleActAddPage from "../pages/eligibleActs/EligibleActAddPage";
import { Error500 } from "src/pages/error/Error500";
import RecommendationAddPage from "src/pages/recommendation/RecommendationAddPage";
import RecommendationListingPage from "src/pages/recommendation/RecommendationListingPage";
import RecommendationUpdatePage from "src/pages/recommendation/RecommendationUpdatePage";
export const APP_ROUTES = [
  {
    path: DASHBOARD_BASE_PATH,
    name: "dashboard",
    component: DashboardPage,
    hidden: false,
  },
  {
    path: PRODUCT_BASE_PATH,
    name: "products",
    component: ProductListingPage,
    hidden: false,
  },
  {
    path: PRODUCT_ADD_PATH,
    name: "Product Add",
    component: ProductAddPage,
    hidden: true,
  },
  {
    path: PRODUCT_UPDATE_PATH,
    name: "Product Update",
    component: ProductUpdatePage,
    hidden: true,
  },
  {
    path: PARAMETER_BASE_PATH,
    name: "parameters",
    component: ParameterListingPage,
    hidden: false,
  },
  {
    path: USERS_BASE_PATH,
    name: "Users",
    component: UsersPage,
    hidden: true,
  },
  {
    path: ELIGIBLE_ACT_ADD_PATH,
    name: "Eligible Act Add",
    component: EligibleActAddPage,
    hidden: true,
  },
  {
    path: ELIGIBLE_ACT_BASE_PATH,
    name: "eligibleActs",
    component: EligibleActsListingPage,
    hidden: false,
  },
  {
    path: ELIGIBLE_ACT_UPDATE_PATH,
    name: "Eligible Act Update",
    component: EligibleActUpdatePage,
    hidden: true,
  },

  {
    path: EVENT_PRICING_ADD_PATH,
    name: "Event Pricing Add",
    component: EventPricingAddPage,
    hidden: true,
  },
  {
    path: EVENT_PRICING_BASE_PATH,
    name: "eventPricings",
    component: EventPricingListingPage,
    hidden: false,
  },
  {
    path: EVENT_PRICING_UPDATE_PATH,
    name: "Event Pricing Update",
    component: EventPricingUpdatePage,
    hidden: true,
  },
  {
    path: RECOMMENDATION_ADD_PATH,
    name: "Recommendation Add",
    component: RecommendationAddPage,
    hidden: true,
  },
  {
    path: RECOMMENDATION_BASE_PATH,
    name: "recommendations",
    component: RecommendationListingPage,
    hidden: false,
  },
  {
    path: RECOMMENDATION_UPDATE_PATH,
    name: "Recommendation Update",
    component: RecommendationUpdatePage,
    hidden: true,
  },

  {
    path: ERROR_PATH,
    name: "Error",
    component: Error500,
    hidden: true,
  },
];
