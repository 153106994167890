import { Col, Row } from "antd";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getAllPackagedProducts } from "src/api";
import { getNameWithNamePrefix } from "src/utils";
import SelectInput from "../SelectInput";

const optionAttributeMap = {
  card: "themeList",
  bankOnline: "offers",
  bancass: "options",
  exemption: "options",
};

export default function PackagedProductSelect(props) {
  const { namePrefix, translationPrefix, className } = props;
  const { t } = useTranslation();
  const selectedLang = useSelector(
    (state) => state?.selectedLanguage?.selectedLanguage
  );
  const { data: packagedProducts, isLoading } = useQuery(
    "packagedProducts",
    getAllPackagedProducts
  );
  const { control, setValue } = useFormContext();
  const code = useWatch({
    name: "functionalId",
    control,
  });
  const selectedProductFunctionalId = useWatch({
    name: getNameWithNamePrefix("product", namePrefix),
    control,
  });

  function filterAndMapPackagedProducts() {
    return packagedProducts
      .filter((e) => e.functionalId !== code)
      .map((elem) => ({
        functionalId: elem.functionalId,
        name:
          elem.functionalId +
          " - " +
          elem.label.find((label) => label.locale === selectedLang)?.content,
      }));
  }

  function getOptions() {
    const selectedProduct = packagedProducts.find(
      (elem) => elem.functionalId === selectedProductFunctionalId
    );
    if (!selectedProduct || !optionAttributeMap[selectedProduct.type])
      return [];
    let unmappedOptions =
      selectedProduct[optionAttributeMap[selectedProduct.type]];
    return unmappedOptions.map((elem) => ({
      functionalId: elem.functionalId,
      name:
        elem.functionalId +
        " - " +
        elem.label.find((label) => label.locale === selectedLang)?.content,
    }));
  }

  return (
    !isLoading && (
      <Row gutter={[16, 12]}>
        <Col span={12}>
          <SelectInput
            name={getNameWithNamePrefix("product", namePrefix)}
            label={t(`${translationPrefix}.product`)}
            className={className}
            data={filterAndMapPackagedProducts()}
            onChange={() => setValue(namePrefix + ".options", [])}
          />
        </Col>
        <Col span={12}>
          <SelectInput
            mode="multiple"
            name={getNameWithNamePrefix("options", namePrefix)}
            label={t(`${translationPrefix}.options`)}
            data={getOptions()}
          />
        </Col>
      </Row>
    )
  );
}
