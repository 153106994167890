import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Serial from "../Serial";
import ParameterSelect from "src/components/generic/inputs/ParameterSelect";

const BilanForm = (props) => {
  const { namePrefix } = props;

  const { t } = useTranslation();

  return (
    <div className="w-full">
      <ParameterSelect
        label={t("bilan.functionalId")}
        name={`${namePrefix}.functionalId`}
        domain="BILAN"
      />
      <Serial namePrefix={`${namePrefix}.serials`} />
    </div>
  );
};

BilanForm.propTypes = {
  namePrefix: PropTypes.string.isRequired,
};

export default BilanForm;
