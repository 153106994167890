import { Form, Input, Select } from "antd";
import { Controller, useFormContext } from "react-hook-form";

export default function SelectInput(props) {
  const {
    name,
    label,
    data: options,
    required,
    startAdornment,
    mode,
    onChange,
  } = props;

  const { control, formDisabled } = useFormContext();

  return (
    <Form.Item style={{ marginBottom: "0px" }}>
      <label>{label}</label>
      <Controller
        name={name}
        control={control}
        rules={{ required }}
        render={({ field }) => (
          <Input.Group size="large">
            <div className="flex justify-start border rounded-lg overflow-hidden">
              {startAdornment ? (
                <div
                  className="p-1 px-3 flex justify-center items-center border-gray-300 border-r rounded-l-md"
                  style={{ backgroundColor: "#FAFAFA" }}
                >
                  {startAdornment}
                </div>
              ) : null}
              <Select
                {...field}
                options={options}
                style={{ width: "100%", borderRadius: "0%" }}
                size="large"
                mode={mode}
                showSearch
                optionFilterProp="name"
                onChange={(e) => {
                  field.onChange(e);
                  if (onChange) onChange(e);
                }}
                // notFoundContent={<Empty description="Aucune donée trouvé" />}
                disabled={formDisabled}
                fieldNames={{ label: "name", value: "functionalId" }}
                showArrow
              />
            </div>
          </Input.Group>
        )}
      />
    </Form.Item>
  );
}
