import {
  AimOutlined,
  CalendarOutlined,
  DollarCircleOutlined,
  PercentageOutlined,
  TagOutlined,
} from "@ant-design/icons";
import { Col, Row, Tabs } from "antd";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Discount from "src/components/generic/Discount";
import InternalisationInput from "src/components/generic/inputs/InternalisationInput";
import ParameterSelect from "src/components/generic/inputs/ParameterSelect";
import SelectInput from "src/components/generic/inputs/SelectInput";
import TextInput from "src/components/generic/inputs/TextInput";
import Pricing from "src/components/generic/Pricing";

const CommissionForm = (props) => {
  const { control } = useFormContext();
  const { namePrefix } = props;
  const { t } = useTranslation();
  const pricingType = useWatch({
    name: `${namePrefix}.pricingType`,
    control,
  });
  const items = [
    {
      label: "Info",
      key: "1",
      children: (
        <Row gutter={[16, 12]} className="mb-2">
          <Col span={12}>
            <TextInput
              label={t("commission.functionalId")}
              name={`${namePrefix}.functionalId`}
              required
              startAdornment={
                <DollarCircleOutlined style={{ fontSize: "25px" }} />
              }
            />
          </Col>
          <Col span={12}>
            <InternalisationInput
              name={`${namePrefix}.label`}
              label={t("commission.label")}
              startAdornment={<TagOutlined style={{ fontSize: "25px" }} />}
            />
          </Col>
          <Col span={12}>
            <SelectInput
              name={`${namePrefix}.pricingType`}
              label={t("commission.pricingType")}
              data={[
                {
                  functionalId: "SETUP",
                  name: "Tarif de mise en place",
                },
                {
                  functionalId: "PERIODIC",
                  name: "Tarif périodique",
                },
              ]}
              startAdornment={<AimOutlined style={{ fontSize: "25px" }} />}
            />
          </Col>
          {pricingType === "PERIODIC" && (
            <Col span={12}>
              <ParameterSelect
                name={`${namePrefix}.periodicity`}
                label={t("commission.periodicty")}
                domain="PERIODICITY"
                startAdornment={
                  <CalendarOutlined style={{ fontSize: "25px" }} />
                }
              />
            </Col>
          )}
          <Col span={12}>
            <ParameterSelect
              name={`${namePrefix}.tva`}
              label={t("commission.tva")}
              domain="TVA"
              startAdornment={
                <PercentageOutlined style={{ fontSize: "25px" }} />
              }
            />
          </Col>
        </Row>
      ),
    },

    {
      label: t("eventPricing.standardPricing"),
      key: "2",
      children: <Pricing namePrefix={`${namePrefix}.standardPricing`} />,
    },

    {
      label: t("eventPricing.preferentialPricing"),
      key: "3",
      children: <Pricing namePrefix={`${namePrefix}.preferentialPricing`} />,
    },
    {
      label: t("eventPricing.discount"),
      key: "4",
      children: <Discount namePrefix={`${namePrefix}.discountList`} />,
    },
  ];

  return <Tabs items={items} />;
};

export default CommissionForm;
