import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  supportedLanguages: [],
};
const init = {
  selectedLanguage: "fr_FR",
};

export const selectedLanguageSlice = createSlice({
  name: "selectedLanguage",
  initialState: init,
  reducers: {
    updateSelectedlanguage: (state, action) => {
      state.selectedLanguage = action.payload;
    },
  },
});
export const supportedLanguagesSlice = createSlice({
  name: "supportedLanguages",
  initialState,
  reducers: {
    update: (state, action) => {
      state.supportedLanguages = action.payload;
    },
  },
});

export const { updateSelectedlanguage } = selectedLanguageSlice.actions;
export const { update } = supportedLanguagesSlice.actions;

export const { reducer: selectedLanguageReducer } = selectedLanguageSlice;
export const { reducer: supportedLanguagesReducer } = supportedLanguagesSlice;
