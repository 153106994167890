import SelectInput from "../SelectInput";

export default function DataMultiSelect(props) {
  const { name, data, label, startAdornment } = props;

  return (
    <SelectInput
      name={name}
      label={label}
      mode="multiple"
      data={data}
      startAdornment={startAdornment}
    />
  );
}
