import React from "react";
import PropTypes from "prop-types";
import PlafondMultiSelect from "src/components/generic/inputs/PlafondMultiSelect";
import TextInput from "src/components/generic/inputs/TextInput";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import { DollarCircleOutlined } from "@ant-design/icons";

const CapForm = (props) => {
  const { namePrefix } = props;

  const { t } = useTranslation();

  return (
    <Row gutter={[16, 12]} className="m-1 mb-5">
      <Col span={12}>
        <TextInput
          label={t("cap.amount")}
          type="number"
          name={`${namePrefix}.amount`}
          required
          startAdornment={<DollarCircleOutlined style={{ fontSize: "25px" }} />}
        />
      </Col>
      <Col span={12}>
        <PlafondMultiSelect
          name={`${namePrefix}.plafondIds`}
          label={t("cap.operations")}
          required
        />
      </Col>
    </Row>
  );
};

CapForm.propTypes = {
  namePrefix: PropTypes.string.isRequired,
};

export default CapForm;
