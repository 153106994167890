import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { BasePage } from "src/pages";
import { APP_BASE_PATH, HOME_BASE_PATH } from "../paths";
import { APP_ROUTES } from "../routes";

const renderRoutes = () => {
  return APP_ROUTES.map((route, index) => {
    const Component = route.component;
    return (
      <Route
        key={`${route.path}-${index}`}
        path={route.path}
        element={<Component />}
      />
    );
  });
};

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path={APP_BASE_PATH} element={<BasePage />}>
        <Route index element={<Navigate replace to={HOME_BASE_PATH} />} />
        {renderRoutes()}
        <Route path="*" element={<Navigate replace to={APP_BASE_PATH} />} />
      </Route>
    </Routes>
  );
};
