import PropTypes from "prop-types";
import { useFormContext, useWatch } from "react-hook-form";
import useParameters from "src/hooks/normalHooks/useParameters";
import DataMultiSelect from "../DataMultiSelect";

export default function PlafondMultiSelect(props) {
  const { name, label, className } = props;

  const { control } = useFormContext();

  const { data: operations, isLoading } = useParameters("OPERATION");

  const data = useWatch({
    control,
    name: name.split(".").slice(0, 2).join(".") + ".plafonds",
  });

  const getParams = (params) =>
    params.filter((par) =>
      data.map((e) => e.operation).includes(par.functionalId)
    );

  return (
    !isLoading && (
      <DataMultiSelect
        name={name}
        label={label}
        className={className}
        data={getParams(operations)}
      />
    )
  );
}

PlafondMultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  errors: PropTypes.object,
  required: PropTypes.bool,
};
