import { Form } from "antd";
import PropTypes from "prop-types";
import { useFormContext, useWatch } from "react-hook-form";

export default function SwitchInput(props) {
  const { name, label } = props;

  const { control, setValue, formDisabled } = useFormContext();
  const value = useWatch({ control, name });

  return (
    <div className="flex items-end h-full justify-between pb-2">
      <span>{label}</span>
      <Form.Item style={{ marginBottom: "0px" }}>
        <div
          onClick={() => {
            if (!formDisabled) setValue(name, !value);
          }}
          data-testid="switch"
          className={
            "cursor-pointer w-12 h-6 rounded-full p-1 flex justify-start transition-all duration-200 " +
            (value ? " bg-orange-400 " : " bg-gray-400 ") +
            (formDisabled
              ? "cursor-not-allowed bg-gray-200"
              : "cursor-pointer ")
          }
        >
          <div
            className={
              "w-4 h-4 bg-white rounded-full z-10 transition-all duration-300 " +
              (value ? "translate-x-6 bg-white" : "")
            }
          />
        </div>
      </Form.Item>
    </div>
  );
}

SwitchInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  control: PropTypes.object,
};
