import { Col, Row } from "antd";
import PropTypes from "prop-types";
import { addParameter, updateParameter } from "src/api";
import Form from "src/components/generic/Form";
import InternalisationInput from "src/components/generic/inputs/InternalisationInput";
import ParameterSelect from "src/components/generic/inputs/ParameterSelect";
import SwitchInput from "src/components/generic/inputs/SwitchInput";
import TextInput from "src/components/generic/inputs/TextInput";
import { mapDataToParameter, mapParameterToData } from "src/utils";
import { PARAMETER_DEFAULT } from "src/utils/defaultsSupplier";
import { PARAMETER_VALIDATION_SCHEMA } from "src/utils/validationSchemas/parameterSchema";

export default function ParameterForm(props) {
  const { domain, close, onParameterAdd, onParameterUpdate, parameter } = props;

  const onSubmit = (data) => {
    const mappedParameter = mapDataToParameter(data, domain);
    if (parameter) {
      updateParameter(parameter.id, mappedParameter).then((res) => {
        onParameterUpdate(parameter);
        close();
      });
    } else {
      addParameter(mappedParameter).then((res) => {
        onParameterAdd(mappedParameter);
        close();
      });
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      onCancel={close}
      validationSchema={PARAMETER_VALIDATION_SCHEMA}
      defaultValues={
        parameter ? mapParameterToData(parameter) : PARAMETER_DEFAULT
      }
    >
      <Row gutter={[16, 16]} className="mb-6">
        <Col span={24}>
          <TextInput label="Code" name="functionalId" disabled={!!parameter} />
        </Col>
        <Col span={24}>
          <TextInput label="Nom" name="name" />
        </Col>
        <Col span={24}>
          <InternalisationInput name="description" label="Description" />
        </Col>
        <Col span={24}>
          <SwitchInput name="active" label="Active" />
        </Col>
        {renderAdditionalFields(domain)}
      </Row>
    </Form>
  );
}

ParameterForm.propTypes = {
  domain: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
};

const renderAdditionalFields = (domain) => {
  if (!domain.domainInputList?.length) return;
  return domain.domainInputList.map((input) => {
    if (input.type === "String")
      return <TextInput name={input.name} label={input.label} />;
    if (input.type?.startsWith("List.Parameter")) {
      return (
        <ParameterSelect
          name={input.name}
          label={input.label}
          domain={input.type.substring(input.type.lastIndexOf(".") + 1)}
        />
      );
    }
    return <></>;
  });
};
