import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";
import Navbar from "src/components/generic/NavBar/NavBar";
import { APP_ROUTES } from "../../routes/routes";

export const BasePage = () => {
  return (
    <div className="w-full min-h-screen h-full bg-gray-100">
      <Navbar pages={renderViewableRoutes()} />
      <div className="h-full w-full">
        <Outlet />
      </div>
    </div>
  );
};

const renderViewableRoutes = () => {
  return APP_ROUTES.filter((route) => !route.hidden);
};

BasePage.propTypes = {
  children: PropTypes.any,
};
