import { CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Row } from "antd";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ArrayAccordionPreviewFields from "../ArrayAccordionPreviewFields/ArrayAccordionPreviewFields";
import ComponentDisabler from "../ComponentDisabler";

export default function ArrayAccordion(props) {
  const {
    name,
    translationPrefix,
    Form,
    defaultValues,
    fieldsNames,
    isDuplicatable = true,
  } = props;

  const { t } = useTranslation();

  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });
  const updatedFields = useWatch({
    control,
    name,
  });

  return (
    <>
      <div className="py-2">
        <h1 className="mb-1">{t(`${translationPrefix}.title`)}</h1>
        <Collapse expandIconPosition="start">
          {fields.length > 0 ? (
            fields.map((field, index) => (
              <Collapse.Panel
                key={field.id}
                header={
                  <Row>
                    <Col span={6}>
                      {t(`${translationPrefix}.titleSingle`) +
                        " " +
                        (index + 1)}
                    </Col>
                    <ArrayAccordionPreviewFields
                      fieldsNames={fieldsNames}
                      array={updatedFields}
                      index={index}
                      t={t}
                      translationPrefix={translationPrefix}
                    />
                  </Row>
                }
              >
                <div>
                  <Form namePrefix={`${name}[${index}]`} />
                  <div className="flex justify-between">
                    {isDuplicatable ? (
                      <ComponentDisabler
                        componentRender={() => (
                          <Button
                            icon={<CopyOutlined style={{ fontSize: "18px" }} />}
                            className="flex items-center"
                            ghost
                            type="primary"
                            onClick={() => {
                              append(updatedFields[index]);
                            }}
                          >
                            {t(`${translationPrefix}.duplicate`)}
                          </Button>
                        )}
                      />
                    ) : (
                      <div />
                    )}
                    <ComponentDisabler
                      componentRender={() => (
                        <Button
                          icon={<DeleteOutlined style={{ fontSize: "18px" }} />}
                          className="flex items-center"
                          danger
                          ghost
                          onClick={() => {
                            remove(index);
                          }}
                        >
                          {t(`${translationPrefix}.delete`)}
                        </Button>
                      )}
                    />
                  </div>
                </div>
              </Collapse.Panel>
            ))
          ) : (
            <div className="w-full bg-white p-2 flex justify-center">
              <p> {t(`${translationPrefix}.noAdded`)} </p>
            </div>
          )}
        </Collapse>
        <ComponentDisabler
          componentRender={() => (
            <Button
              className="w-full mt-2"
              type="primary"
              ghost
              onClick={() => {
                append(defaultValues);
              }}
            >
              + {t(`${translationPrefix}.add`)}
            </Button>
          )}
        />
      </div>
    </>
  );
}
