import PropTypes from "prop-types";
import ArrayAccordion from "src/components/generic/ArrayAccordion";
import { EXEMPTIONOPTION_DEFAULT } from "src/utils/defaultsSupplier";
import ExemptionOptionForm from "../ExemptionOptionForm";

export default function ExemptionOption(props) {
  const { namePrefix } = props;
  const name = namePrefix ? `${namePrefix}.options` : "options";
  return (
    <ArrayAccordion
      name={name}
      translationPrefix="exemptionOption"
      Form={ExemptionOptionForm}
      defaultValues={EXEMPTIONOPTION_DEFAULT}
      fieldsNames={["functionalId", "label"]}
    />
  );
}

ExemptionOption.propTypes = {
  namePrefix: PropTypes.string,
};
