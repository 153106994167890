import { SUPPORTING_DOCUMENT_DEFAULT } from "../../../../utils/defaultsSupplier";
import ArrayAccordion from "../../../generic/ArrayAccordion";
import SupportingDocumentForm from "../SupportinDocumentForm";

export default function SupportingDocument(props) {
  return (
    <ArrayAccordion
      name="supportingDocuments"
      translationPrefix="supportingDocuments"
      Form={SupportingDocumentForm}
      defaultValues={SUPPORTING_DOCUMENT_DEFAULT}
      fieldsNames={["functionalId", "label", "sides"]}
    />
  );
}
