import { ELIGIBLE_ACT_PATH } from "src/api/apiPaths";
import HttpService from "../../services/HttpService";

const instance = HttpService.getAxiosClient();

export const addEligibleAct = async (eligibleAct) => {
  const res = await instance.post(ELIGIBLE_ACT_PATH, eligibleAct);
  return res.data;
};

export const getAllEligibleActs = async (page, size) => {
  const res = await instance.get(
    `${ELIGIBLE_ACT_PATH}?page=${page}&size=${size}`
  );
  return res.data;
};

export const getEligibleActById = async (eligibleActId) => {
  const res = await instance.get(`${ELIGIBLE_ACT_PATH}${eligibleActId}`);
  return res.data;
};

export const updateEligibleAct = async (eligibleAct, id) => {
  const res = await instance.put(`${ELIGIBLE_ACT_PATH}${id}`, eligibleAct);
  return res.data;
};

export const getEligibleActVersions = async (functionalId) => {
  const res = await instance.get(
    `${ELIGIBLE_ACT_PATH}${functionalId}/versions`
  );
  return res.data;
};

export const publishEligibleActDraft = async (functionalId) => {
  const res = await instance.patch(
    `${ELIGIBLE_ACT_PATH}${functionalId}/publish`
  );
  return res.data;
};

export const unpublishEligibleActDraft = async (functionalId) => {
  const res = await instance.patch(
    `${ELIGIBLE_ACT_PATH}${functionalId}/unpublish`
  );
  return res.data;
};

export const createEligibleActDraft = async (functionalId) => {
  const res = await instance.put(`${ELIGIBLE_ACT_PATH}${functionalId}/draft`);
  return res.data;
};
