import PropTypes from "prop-types";
import ArrayAccordion from "src/components/generic/ArrayAccordion";
import { BILAN_DEFAULT } from "src/utils/defaultsSupplier";
import BilanForm from "../BilanForm";

export default function Bilan(props) {
  const { namePrefix } = props;
  return (
    <ArrayAccordion
      name={`${namePrefix}`}
      translationPrefix="bilan"
      Form={BilanForm}
      defaultValues={BILAN_DEFAULT}
      fieldsNames={["functionalId"]}
    />
  );
}

Bilan.propTypes = {
  namePrefix: PropTypes.string.isRequired,
};
