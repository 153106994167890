import React from "react";
import CardDisplay from "src/components/generic/CardDisplay";
import AdditionalAttribute from "../../generic/AdditionalAttribute";
import PackFormula from "../PackFormula/PackFormula";

export default function PackInfo() {
  return (
    <CardDisplay title="Pack Info">
      <PackFormula />
      <AdditionalAttribute />
    </CardDisplay>
  );
}
