import { EVENT_PRICINGS_PATH } from "src/api/apiPaths";
import HttpService from "../../services/HttpService";
const instance = HttpService.getEventPricingAxios();

export const addEventPricing = async (eventPricing) => {
  const res = await instance.post(EVENT_PRICINGS_PATH, eventPricing);
  return res.data;
};
export const getAllEventPricings = async () => {
  const res = await instance.get(EVENT_PRICINGS_PATH);
  return res.data;
};
export const getEventPricingById = async (eventPricingId) => {
  const res = await instance.get(`${EVENT_PRICINGS_PATH}${eventPricingId}`);
  return res.data;
};
export const updateEventPricing = async (eventPricing, id) => {
  const res = await instance.put(`${EVENT_PRICINGS_PATH}${id}`, eventPricing);
  return res.data;
};

export const getEventPricingVersions = async (functionalId) => {
  const res = await instance.get(
    `${EVENT_PRICINGS_PATH}${functionalId}/versions`
  );
  return res.data;
};

export const publishEventPricingDraft = async (functionalId) => {
  const res = await instance.patch(
    `${EVENT_PRICINGS_PATH}${functionalId}/publish`
  );
  return res.data;
};

export const unpublishEventPricingDraft = async (functionalId) => {
  const res = await instance.patch(
    `${EVENT_PRICINGS_PATH}${functionalId}/unpublish`
  );
  return res.data;
};

export const createEventPricingDraft = async (functionalId) => {
  const res = await instance.put(`${EVENT_PRICINGS_PATH}${functionalId}/draft`);
  return res.data;
};
