import PropTypes from "prop-types";
import ConstraintCompsite from "src/components/constraint/form/ConstraintCompsite/ConstraintCompsite";
import MultiChipsInput from "src/components/generic/inputs/MultiChipInput";
import PackagedProductSelect from "src/components/generic/inputs/PackagedProductMultiSelect";
import ParameterSelect from "src/components/generic/inputs/ParameterSelect";
import { getNameWithNamePrefix } from "src/utils";
import ParameterMultiSelect from "../../../generic/inputs/ParameterMultiSelect";
import TextInput from "../../../generic/inputs/TextInput";
import ProductMultiSelect from "../../../generic/ProductMultiSelect";
import Bilan from "../Bilan";
import GenericConstraintForm from "../GenericConstraintForm";
import ModuleMultiSelect from "../ModuleMultiSelect";

export default function ConstraintSpecificFields(props) {
  const { type, constraintTypes, namePrefix } = props;
  return (
    <div className="w-full">
      {type?.constraintInputList.map((constraintInput, key) =>
        getRightInput(constraintInput, key, constraintTypes, namePrefix)
      )}
    </div>
  );
}

ConstraintSpecificFields.propTypes = {
  type: PropTypes.object,
};

function getRightInput(constraintInput, key, constraintTypes, namePrefix) {
  const { type, label, name } = constraintInput;
  if (type === "Integer")
    return (
      <TextInput
        key={key}
        name={getNameWithNamePrefix(name, namePrefix)}
        className="mb-2"
        type="number"
        label={label}
      />
    );
  if (type === "List.Module")
    return (
      <ModuleMultiSelect
        key={key}
        name={getNameWithNamePrefix(name, namePrefix)}
        label={label}
      />
    );

  if (type === "CustomForms.Bilan")
    return (
      <Bilan key={key} namePrefix={getNameWithNamePrefix(name, namePrefix)} />
    );

  if (type?.startsWith("List.Parameter"))
    return (
      <ParameterMultiSelect
        key={key}
        name={getNameWithNamePrefix(name, namePrefix)}
        label={label}
        className="mb-2"
        domain={type.substring(type.lastIndexOf(".") + 1)}
      />
    );

  if (type?.startsWith("Parameter"))
    return (
      <ParameterSelect
        key={key}
        name={getNameWithNamePrefix(name, namePrefix)}
        label={label}
        domain={type.substring(type.lastIndexOf(".") + 1)}
      />
    );

  if (type?.startsWith("List.Product"))
    return (
      <ProductMultiSelect
        className="mb-2"
        key={key}
        name={getNameWithNamePrefix(name, namePrefix)}
        label={label}
      />
    );

  if (type === "CustomForms.Generic")
    return <GenericConstraintForm key={key} />;

  if (type === "Text")
    return (
      <TextInput
        key={key}
        name={getNameWithNamePrefix(name, namePrefix)}
        className="mb-2"
        type="text"
        label={label}
      />
    );

  if (type === "List.Text")
    return (
      <MultiChipsInput
        key={key}
        name={getNameWithNamePrefix(name, namePrefix)}
        className="mb-2"
        label={label}
      />
    );

  if (type === "Constraints")
    return (
      <ConstraintCompsite
        key={key}
        namePrefix={namePrefix}
        constraintTypes={constraintTypes}
      />
    );

  if (type === "Possession")
    return (
      <PackagedProductSelect
        key={key}
        translationPrefix="constraints"
        namePrefix={namePrefix}
        constraintTypes={constraintTypes}
      />
    );
}
