import { PRICING_DEFAULT } from "src/utils/defaultsSupplier";
import ArrayAccordion from "../ArrayAccordion";
import PricingForm from "../PricingForm";

export default function Pricing(props) {
  const { namePrefix } = props;

  return (
    <>
      <ArrayAccordion
        name={`${namePrefix}`}
        translationPrefix="pricing"
        Form={PricingForm}
        defaultValues={PRICING_DEFAULT}
        fieldsNames={["type", "price", "currency"]}
      />
    </>
  );
}
