import PropTypes from "prop-types";
import useParameters from "src/hooks/normalHooks/useParameters";
import SelectInput from "../SelectInput";

export default function ParameterSelect(props) {
  const { name, label, domain, startAdornment } = props;

  const { data, isLoading } = useParameters(domain);

  return (
    !isLoading && (
      <SelectInput
        data={data}
        name={name}
        label={label}
        startAdornment={startAdornment}
      />
    )
  );
}

ParameterSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  domain: PropTypes.string.isRequired,
  required: PropTypes.bool,
  errors: PropTypes.object,
};
