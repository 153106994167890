import PropTypes from "prop-types";
import ArrayAccordion from "src/components/generic/ArrayAccordion";
import { FORMULA_DEFAULT } from "src/utils/defaultsSupplier";
import FormulaForm from "../FormulaForm";

export default function Formula(props) {
  const { namePrefix } = props;

  return (
    <ArrayAccordion
      name={`${namePrefix}.formulas`}
      translationPrefix="formulas"
      Form={FormulaForm}
      defaultValues={FORMULA_DEFAULT}
      fieldsNames={["formulaCode", "formulaLabel"]}
    />
  );
}

Formula.propTypes = {
  namePrefix: PropTypes.string.isRequired,
};
