import { getNameWithNamePrefix } from "src/utils";
import TextInput from "../../../generic/inputs/TextInput";

export default function ConstraintCommonFields(props) {
  const { namePrefix } = props;
  return (
    <div className="w-full">
      <TextInput
        name={getNameWithNamePrefix("label", namePrefix)}
        label="Label"
        className="mb-2"
      />
      <TextInput
        name={getNameWithNamePrefix("description", namePrefix)}
        label="Description"
        className="mb-2"
      />
    </div>
  );
}
