import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDomains } from "src/api";
import ParameterLister from "../../components/parameter/listing/ParameterLister";

export const ParameterListingPage = () => {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);

  useEffect(() => {
    const categories = {};
    getDomains().then((res) => {
      res.forEach((par) =>
        categories[par.category]
          ? categories[par.category].push(par)
          : (categories[par.category] = [par])
      );
      setItems(
        Object.keys(categories).map((cat) => {
          return {
            label: t("subnav." + cat),
            key: cat,
            children: (
              <>
                {categories[cat].map((domain) => {
                  return <ParameterLister key={domain.id} domain={domain} />;
                })}
              </>
            ),
          };
        })
      );
    });
  }, [t]);

  return (
    <div className="p-6 w-full h-full">
      <Tabs centered items={items} />
    </div>
  );
};
