import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import InternalisationInput from "src/components/generic/inputs/InternalisationInput";
import SwitchInput from "src/components/generic/inputs/SwitchInput";
import TextInput from "src/components/generic/inputs/TextInput";
import ProductOptionPair from "../ProductOptionPair/ProductOptionPair";

const ProductOfferForm = (props) => {
  const { namePrefix } = props;
  const { t } = useTranslation();
  return (
    <Row gutter={[16, 12]} className="mb-2">
      <Col span={12}>
        <TextInput
          name={`${namePrefix}.functionalId`}
          label={t("productOffer.functionalId")}
        />
      </Col>
      <Col span={12}>
        <InternalisationInput
          name={`${namePrefix}.label`}
          label={t("productOffer.label")}
        />
      </Col>
      <Col span={24}>
        <SwitchInput
          name={`${namePrefix}.optional`}
          label={t("productOffer.optional")}
        />
      </Col>
      <Col span={24}>
        <ProductOptionPair namePrefix={namePrefix} />
      </Col>
    </Row>
  );
};

export default ProductOfferForm;
