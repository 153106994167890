import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import modalConfirm from "src/utils/modalConfirm";

const VersioningButtons = (props) => {
  const navigate = useNavigate();
  const { element, createDraft, publishDraft, unpublishDraft, updateUrl } =
    props;
  const { t } = useTranslation();
  return (
    <>
      {element.version === "draft" ? (
        <div className="mr-5">
          <Button
            type="primary"
            ghost
            onClick={() => {
              modalConfirm(
                "You are about to publish this draft, are you sure?",
                () =>
                  publishDraft(element.id).then((res) => {
                    navigate(updateUrl + res.id);
                    window.location.reload();
                  })
              );
            }}
          >
            {t("versioning.publish")}
          </Button>
        </div>
      ) : (
        <div className="mr-5">
          <Button
            type="primary"
            ghost
            onClick={() => {
              modalConfirm(
                "You are about to overwrite the old draft, are you sure?",
                () =>
                  createDraft(element.id).then((res) => {
                    navigate(updateUrl + res.id);
                    window.location.reload();
                  })
              );
            }}
          >
            {t("versioning.createDraft")}
          </Button>
        </div>
      )}
      {element.published ? (
        <div className="mr-5">
          <Button
            type="primary"
            ghost
            onClick={() => {
              modalConfirm(
                "You are about to unpublish this version, are you sure?",
                () =>
                  unpublishDraft(element.id).then((res) => {
                    navigate(updateUrl + res.id);
                    window.location.reload();
                  })
              );
            }}
          >
            {t("versioning.unpublish")}
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default VersioningButtons;
