import {
  mapDataToInternationalisaion,
  mapInternationalisationToData,
} from "../mapperHelpers";
import {
  mapDataToTargets,
  mapTargetsToData,
} from "../mapperHelpers/mapperHelpers";

export const mapEligibleActToData = (eligibleAct) => {
  const data = {
    functionalId: eligibleAct.functionalId,
    targetType: eligibleAct.targetType,
    act: eligibleAct.act,
    tags: eligibleAct.tags,
    products: [],
    families: [],
    groups: [],
    ...mapTargetsToData(eligibleAct),
    consents: mapConsentsToData(eligibleAct.consents),
    supportingDocuments: mapSupportingDocumentsToData(
      eligibleAct.supportingDocuments
    ),
    constraints: eligibleAct.constraints,
  };
  return data;
};

const mapConsentsToData = (consents) => {
  return consents.map((consent) => ({
    ...consent,
    descriptions: mapInternationalisationToData(consent.descriptions),
  }));
};
const mapSupportingDocumentsToData = (supportingDocuments) => {
  return supportingDocuments.map((supportingDocument) => ({
    ...supportingDocument,
    label: mapInternationalisationToData(supportingDocument.label),
  }));
};

export const mapDataToEligibleAct = (data) => {
  return {
    functionalId: data.functionalId,
    targetType: data.targetType,
    act: data.act,
    tags: data.tags,
    targets: mapDataToTargets(data),
    consents: mapDataToConsents(data.consents),
    supportingDocuments: mapDataToSupportingDocuments(data.supportingDocuments),
    constraints: data.constraints,
  };
};

const mapDataToConsents = (data) => {
  return data.map((d) => ({
    ...d,
    descriptions: mapDataToInternationalisaion(d.descriptions),
  }));
};

const mapDataToSupportingDocuments = (data) => {
  return data.map((d) => ({
    ...d,
    label: mapDataToInternationalisaion(d.label),
  }));
};
