import PropTypes from "prop-types";
import useParameters from "src/hooks/normalHooks/useParameters";
import DataMultiSelect from "../DataMultiSelect";

export default function ParameterMultiSelect(props) {
  const { name, label, domain, startAdornment, className } = props;

  const { data, isLoading } = useParameters(domain);

  return (
    !isLoading && (
      <DataMultiSelect
        name={name}
        label={label}
        className={className}
        required={false}
        data={data}
        startAdornment={startAdornment}
      />
    )
  );
}

ParameterMultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  domain: PropTypes.string.isRequired,
  className: PropTypes.string,
  errors: PropTypes.object,
};
