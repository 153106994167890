import ArrayAccordion from "src/components/generic/ArrayAccordion";
import PackFormulaForm from "../PackFormulaForm/PackFormulaForm";

export default function PackFormula() {
  return (
    <ArrayAccordion
      name="formulaList"
      translationPrefix="formulaList"
      Form={PackFormulaForm}
      defaultValues={{}}
    />
  );
}
