import {
  mapDataToInternationalisaion,
  mapInternationalisationToData,
} from "../../mapperHelpers";

export const mapBankOnlineToData = (bankOnline) => {
  return {
    offers: bankOnline.offers.map(mapOfferToData),
  };
};

const mapOfferToData = (offer) => {
  return {
    functionalId: offer.functionalId,
    codeOfferTech: offer.codeOfferTech,
    label: mapInternationalisationToData(offer.label),
    description: mapInternationalisationToData(offer.description),
    modules: offer.modules.map(mapModuleToData),
  };
};

const mapModuleToData = (offerModule) => {
  return {
    functionalId: offerModule.functionalId,
    codeProvider: offerModule.codeProvider,
    label: mapInternationalisationToData(offerModule.label),
    optional: !!offerModule.optional,
    options: offerModule.options.map(mapOptionToData),
  };
};

const mapOptionToData = (option) => {
  return {
    description: mapInternationalisationToData(option.description),
    additionalPrice: option.additionalPrice,
    periodicity: option.periodicity,
    remarks: option.remarks,
  };
};

export const mapDataToBankOnline = (data) => {
  return {
    type: "bankOnline",
    offers: data.offers.map(mapDataToOffer),
  };
};

const mapDataToOffer = (data) => {
  return {
    functionalId: data.functionalId,
    codeOfferTech: data.codeOfferTech,
    label: mapDataToInternationalisaion(data.label),
    description: mapDataToInternationalisaion(data.description),
    modules: data.modules.map(mapDataToModule),
  };
};

const mapDataToModule = (data) => {
  return {
    functionalId: data.functionalId,
    codeProvider: data.codeProvider,
    label: mapDataToInternationalisaion(data.label),
    optional: data.optional,
    options: data.options.map(mapDataToOption),
  };
};

const mapDataToOption = (data) => {
  return {
    description: mapDataToInternationalisaion(data.description),
    additionalPrice: data.additionalPrice,
    periodicity: data.periodicity,
    remarks: data.remarks,
  };
};
