import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CardDisplay from "src/components/generic/CardDisplay";
import { familyMappers } from "src/utils/familyMappers";

export default function SpecificInfo() {
  const { control } = useFormContext();
  const family = useWatch({
    control,
    name: "family",
  });
  const { t } = useTranslation();
  if (!family) return <MessageDisdplayer message={t("family.validFamily")} />;
  return (
    familyMappers[family]?.component ||
    familyMappers["GENERIC_PRODUCT"]?.component
  );
}

const MessageDisdplayer = ({ message }) => {
  return (
    <CardDisplay>
      <div className="flex justify-center text-red-600">{message}</div>
    </CardDisplay>
  );
};
