import {
  AimOutlined,
  HighlightOutlined,
  QrcodeOutlined,
  TagsOutlined,
} from "@ant-design/icons";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getEligibleActVersions } from "src/api/eligibleAct";
import ConstraintsAdd from "src/components/constraint/form/ConstraintsAdd";
import CardDisplay from "src/components/generic/CardDisplay";
import Form from "src/components/generic/Form";
import ParameterMultiSelect from "src/components/generic/inputs/ParameterMultiSelect";
import ParameterSelect from "src/components/generic/inputs/ParameterSelect";
import SelectInput from "src/components/generic/inputs/SelectInput";
import TextInput from "src/components/generic/inputs/TextInput";
import Loading from "src/components/generic/Loading";
import VersionsTimeLine from "src/components/product/form/generic/VersionsTimeLine";
import useVersions from "src/hooks/normalHooks/useVersions";
import { Error500 } from "src/pages/error";
import { ELIGIBLE_ACT_BASE_PATH } from "src/routes/paths";
import { ELIGIBLE_ACT_DEFAULT } from "src/utils/defaultsSupplier";
import { getTargetTypes } from "src/utils/getTargetTypes";
import { mapEligibleActToData } from "src/utils/mappers/eligibleActMapper";
import { ELIGIBLE_ACT_VALIDATION_SCHEMA } from "src/utils/validationSchemas/eligibleActSchema";
import Targets from "../../../eventPricing/Targets";
import Consent from "../Consent";
import SupportingDocument from "../SupportingDocument";

export default function EligibleActForm(props) {
  const {
    onSubmit,
    eligibleAct,
    submitDisabled,
    additionalButtons,
    isUpdateForm,
    formDisabled,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { versions, isLoading, isError } = useVersions(
    eligibleAct?.id,
    getEligibleActVersions
  );
  if (isLoading) return <Loading />;
  if (isError) return <Error500 />;
  return (
    <div className="grid grid-cols-5 gap-2">
      <CardDisplay title="Versions" titleCentered={true}>
        <VersionsTimeLine versions={versions} path="/eligible-acts/update/" />
      </CardDisplay>
      <div className="col-span-4">
        <Form
          onSubmit={onSubmit}
          submitDisabled={submitDisabled}
          formDisabled={formDisabled}
          defaultValues={
            eligibleAct
              ? mapEligibleActToData(eligibleAct)
              : ELIGIBLE_ACT_DEFAULT
          }
          onCancel={() => navigate(ELIGIBLE_ACT_BASE_PATH)}
          validationSchema={ELIGIBLE_ACT_VALIDATION_SCHEMA}
          additionalButtons={additionalButtons}
        >
          <Row gutter={[16, 12]} className="mb-5">
            <Col span={12}>
              <TextInput
                name="functionalId"
                label={t("eligibleAct.functionalId")}
                startAdornment={<QrcodeOutlined style={{ fontSize: "25px" }} />}
                disabled={isUpdateForm}
              />
            </Col>
            <Col span={12}>
              <SelectInput
                name="targetType"
                label={t("eligibleAct.targetType")}
                data={getTargetTypes()}
                startAdornment={<AimOutlined style={{ fontSize: "25px" }} />}
              />
            </Col>
            <Col span={12}>
              <Targets namePrefix="targets" />
            </Col>
            <Col span={12}>
              <ParameterSelect
                name="act"
                label={t("eligibleAct.act")}
                domain="ACT"
                startAdornment={
                  <HighlightOutlined style={{ fontSize: "25px" }} />
                }
              />
            </Col>
            <Col span={24}>
              <ParameterMultiSelect
                name="tags"
                label={t("eligibleAct.tags")}
                domain="TAG"
                startAdornment={<TagsOutlined style={{ fontSize: "25px" }} />}
              />
            </Col>
            <Col span={24}>
              <Consent />
            </Col>

            <Col span={24}>
              <SupportingDocument />
            </Col>

            <Col span={24}>
              <h2>{t("eligibleAct.constraints")}</h2>
              <ConstraintsAdd />
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}
