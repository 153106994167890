import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "./routes/AppRoutes";
import frFR from "antd/locale/fr_FR";

import { ConfigProvider } from "antd";
import { Suspense } from "react";
import Loading from "./components/generic/Loading";

function App() {
  return (
    <Suspense fallback={<Loading />}>
      <ConfigProvider
        locale={frFR}
        theme={{
          token: {
            colorPrimary: "#fb923c",
          },
        }}
      >
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </ConfigProvider>
    </Suspense>
  );
}

export default App;
