import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Loading from "src/components/generic/Loading";
import ProductForm from "src/components/product/form/ProductForm";

import { mapDataToProduct } from "src/utils";
import { PRODUCT_BASE_PATH } from "src/routes/paths";
import { useQuery, useQueryClient } from "react-query";
import { Error500 } from "src/pages/error";
import VersioningButtons from "src/components/generic/VersioningButtons/VersioningButtons";
import {
  createProductDraft,
  publishProductDraft,
  unpublishProductDraft,
  getProductById,
  updateProduct,
} from "src/api";

export const ProductUpdatePage = () => {
  const { functionalId: id } = useParams();
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const {
    data: product,
    isLoading,
    isError,
  } = useQuery(["getProductById", id], () => getProductById(id));

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const onSubmit = async (data) => {
    try {
      setSubmitDisabled(true);
      const _product = mapDataToProduct(data);
      await updateProduct(_product, id);
      queryClient.invalidateQueries({ queryKey: ["getProductById"] });
      navigate(PRODUCT_BASE_PATH, {
        state: {
          toastContent: "Product updated successfully!",
        },
      });
    } catch {
      toast.error("There was an error, couldn't update product");
    } finally {
      setSubmitDisabled(false);
    }
  };

  if (isLoading) return <Loading />;
  if (isError) return <Error500 />;

  return (
    <ProductForm
      product={product}
      {...{
        onSubmit,
        isUpdateForm: true,
        formDisabled: product.version !== "draft",
        submitDisabled,
        additionalButtons: (
          <VersioningButtons
            element={product}
            createDraft={createProductDraft}
            publishDraft={publishProductDraft}
            unpublishDraft={unpublishProductDraft}
            updateUrl="/products/update/"
          />
        ),
      }}
    />
  );
};
