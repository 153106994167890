import React from "react";
import PropTypes from "prop-types";
import TextInput from "src/components/generic/inputs/TextInput";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";

const SerialForm = (props) => {
  const { namePrefix } = props;

  const { t } = useTranslation();

  return (
    <Row gutter={[16, 12]} className="mb-2">
      <Col span={24}>
        <TextInput
          label={t("serial.minSerial")}
          type="number"
          name={`${namePrefix}.minSerial`}
          required
        />
      </Col>
      <Col span={24}>
        <TextInput
          label={t("serial.maxSerial")}
          type="number"
          name={`${namePrefix}.maxSerial`}
          required
        />
      </Col>
    </Row>
  );
};

SerialForm.propTypes = {
  namePrefix: PropTypes.string.isRequired,
};

export default SerialForm;
