import { mapDataToDiscounts, mapDiscountsToData } from "../discountMapper";
import {
  mapDataToInternationalisaion,
  mapInternationalisationToData,
  mapDataToTargets,
  mapTargetsToData,
} from "../mapperHelpers/mapperHelpers";
import { mapDataToPrices, mapPricesToData } from "../pricingMapper";

export const mapEventPricingToData = (eventPricing) => {
  return {
    id: eventPricing.id,
    functionalId: eventPricing.functionalId,
    targetType: eventPricing.targetType,
    products: [],
    families: [],
    groups: [],
    descriptions: mapInternationalisationToData(eventPricing.descriptions),
    ...mapTargetsToData(eventPricing),
    act: eventPricing.act,
    tags: eventPricing.tags,
    commissions: eventPricing.commissions.map(mapCommissionToData),
  };
};

export const mapDataToEventPricing = (data) => {
  return {
    id: data.id,
    functionalId: data.functionalId,
    targetType: data.targetType,
    targets: mapDataToTargets(data),
    act: data.act,
    descriptions: mapDataToInternationalisaion(data.descriptions),
    tags: data.tags,
    commissions: data.commissions.map(mapDataToCommission),
  };
};

const mapCommissionToData = (commission) => {
  return {
    functionalId: commission.functionalId,
    label: mapInternationalisationToData(commission.label),
    pricingType: commission.pricingType,
    periodicity: commission.periodicity,
    tva: commission.tva,
    standardPricing: mapPricesToData(
      commission.pricingList.filter((price) => price.standard)
    ),
    preferentialPricing: mapPricesToData(
      commission.pricingList.filter((price) => !price.standard)
    ),

    discountList: mapDiscountsToData(commission.discountList),
  };
};

const mapDataToCommission = (data) => {
  return {
    functionalId: data.functionalId,
    label: mapDataToInternationalisaion(data.label),
    pricingType: data.pricingType,
    periodicity: data.periodicity,
    tva: data.tva,
    pricingList: [
      ...mapDataToPrices(data.standardPricing, true),
      ...mapDataToPrices(data.preferentialPricing, false),
    ],
    discountList: mapDataToDiscounts(data.discountList),
  };
};
