import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addEventPricing } from "src/api/eventPricing";
import CardDisplay from "src/components/generic/CardDisplay";
import { EVENT_PRICING_BASE_PATH } from "src/routes/paths";
import EventPricingForm from "../../../components/eventPricing/EventPricingForm";
import { mapDataToEventPricing } from "../../../utils/mappers/eventPricingMapper";

function EventPricingAddPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const onSubmit = async (data) => {
    try {
      setSubmitDisabled(true);
      const eventPricing = mapDataToEventPricing(data);
      await addEventPricing(eventPricing);
      navigate(EVENT_PRICING_BASE_PATH, {
        state: {
          toastContent: "Event Pricings updated successfully!",
        },
      });
    } catch (ex) {
      toast.error("there was an error, couldnt add event Pricing");
    } finally {
      setSubmitDisabled(false);
    }
  };

  return (
    <div className="p-3">
      <CardDisplay title={t("eventPricing.add")} titleCentered>
        <EventPricingForm submitDisabled={submitDisabled} onSubmit={onSubmit} />
      </CardDisplay>
    </div>
  );
}

export default EventPricingAddPage;
