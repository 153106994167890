import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addRecommendation } from "src/api/recommendation";
import CardDisplay from "src/components/generic/CardDisplay";
import RecommendationForm from "src/components/recommendation/form/RecommendationForm";
import { RECOMMENDATION_BASE_PATH } from "src/routes/paths";
import { mapDataToRecommendation } from "src/utils/mappers/recommendationMapper";
function RecommendationAddPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const onSubmit = async (data) => {
    try {
      setSubmitDisabled(true);
      const mappedRecommendation = mapDataToRecommendation(data);
      await addRecommendation(mappedRecommendation);
      navigate(RECOMMENDATION_BASE_PATH, {
        state: {
          toastContent: "Recommendation added successfully!",
        },
      });
    } catch (err) {
      toast.error("There was an error, couldn't add Recommendation");
    } finally {
      setSubmitDisabled(false);
    }
  };
  return (
    <div className="p-3">
      <CardDisplay titleCentered title={t("recommendation.add")}>
        <RecommendationForm
          submitDisabled={submitDisabled}
          onSubmit={onSubmit}
        />
      </CardDisplay>
    </div>
  );
}

export default RecommendationAddPage;
