import CardDisplay from "src/components/generic/CardDisplay";
import ExemptionOption from "../ExemptionOption";
import AdditionalAttribute from "../../generic/AdditionalAttribute";

export default function Exemption() {
  return (
    <CardDisplay title="Exonération Info">
      <ExemptionOption />
      <AdditionalAttribute />
    </CardDisplay>
  );
}
