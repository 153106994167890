import { familyMappers } from "src/utils/familyMappers";
import { mapDateToData } from "../dateMapper";
import {
  mapDataToInternationalisaion,
  mapInternationalisationToData,
} from "../mapperHelpers";

const mapAdditionalAttributesToData = (attributes) => {
  return Object.keys(attributes).map((key) => ({
    name: key,
    type: attributes[key].type,
    value: { [attributes[key].type]: attributes[key].value },
  }));
};

const mapDataToAdditionAttributes = (data) => {
  const res = {};
  data.forEach(
    (elem) =>
      (res[elem.name] = {
        type: elem.type,
        value: elem.value[elem.type],
      })
  );
  return res;
};

const mapDataToProductGenericField = (data) => {
  const additionalAttributes = mapDataToAdditionAttributes(
    data.additionalAttributes
  );
  return {
    functionalId: data.functionalId,
    provider: data.provider,
    providerProductCode: "hard coded code",
    imageLink: data.imageLink,
    label: mapDataToInternationalisaion(data.name),
    descriptions: mapDataToInternationalisaion(data.description),
    active: !!data.active,
    salable: true,
    version: "draft",
    family: data.family,
    groupList: data.groups,
    canals: data.canals,
    sellingModes: data.sellingModes,
    activationStartDate: data.activationStartDate,
    activationEndDate: data.activationEndDate,
    periodAmount: data.periodAmount,
    periodValidity: data.periodValidity,
    renewable: data.renewable,
    tagList: data.tagList,
    additionalAttributes,
  };
};

const mapProductGenericFieldsToData = (product) => {
  const additionalAttributes = mapAdditionalAttributesToData(
    product.additionalAttributes
  );
  return {
    functionalId: product.functionalId,
    name: mapInternationalisationToData(product.label),
    description: mapInternationalisationToData(product.descriptions),
    family: product.family,
    provider: product.provider,
    groups: product.groupList,
    imageLink: product.imageLink,
    canals: product.canals,
    sellingModes: product.sellingModes,
    active: product.active,
    activationStartDate: mapDateToData(product.activationStartDate),
    activationEndDate: mapDateToData(product.activationEndDate),
    periodAmount: product.periodAmount,
    periodValidity: product.periodValidity,
    renewable: product.renewable,
    tagList: product.tagList,
    additionalAttributes,
  };
};

export const mapProductToData = (product) => {
  if (!product) return;
  return {
    ...mapProductGenericFieldsToData(product),
    ...(familyMappers[product.family]
      ? familyMappers[product.family].mapToData(product)
      : familyMappers["GENERIC_PRODUCT"].mapToData(product)),
  };
};

export const mapDataToProduct = (data) => {
  if (!data) return;
  return {
    ...mapDataToProductGenericField(data),
    ...(familyMappers[data.family]
      ? familyMappers[data.family].mapFromData(data)
      : familyMappers["GENERIC_PRODUCT"].mapFromData(data)),
  };
};
