import ArrayAccordion from "src/components/generic/ArrayAccordion";
import ProductOptionPairForm from "../ProductOptionPairForm/ProductOptionPairForm";

export default function ProductOptionPair(props) {
  const { namePrefix } = props;
  return (
    <ArrayAccordion
      name={`${namePrefix}.productOption`}
      translationPrefix="productOption"
      Form={ProductOptionPairForm}
      defaultValues={{}}
    />
  );
}
