import CardDisplay from "src/components/generic/CardDisplay";
import AdditionalAttribute from "../generic/AdditionalAttribute";

export default function GenericProduct() {
  return (
    <CardDisplay title="Info">
      <AdditionalAttribute />
    </CardDisplay>
  );
}
