import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import TextInput from "src/components/generic/inputs/TextInput";
import InternalisationInput from "src/components/generic/inputs/InternalisationInput";

const ExemptionOptionForm = (props) => {
  const { namePrefix } = props;
  const { t } = useTranslation();
  return (
    <Row gutter={[16, 12]} className="m-1 mb-5">
      <Col span={12}>
        <TextInput
          name={`${namePrefix}.functionalId`}
          label={t("exemptionOption.functionalId")}
        />
      </Col>

      <Col span={12}>
        <InternalisationInput
          name={`${namePrefix}.label`}
          label={t("exemptionOption.label")}
        />
      </Col>

      <Col span={24}>
        <InternalisationInput
          name={`${namePrefix}.description`}
          label={t("exemptionOption.description")}
        />
      </Col>
    </Row>
  );
};

export default ExemptionOptionForm;
