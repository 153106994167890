import axios from "axios";
import UserService from "./UserService";
const BASE_URL = process.env.REACT_APP_API_URL; // "https://catalogue-api.campus.clusterdiali.me/api/v1/admin";
const HttpMethods = { GET: "GET", POST: "POST", PUT: "PUT" };

const _axios = axios.create({
  baseURL: BASE_URL,
  timeout: 60000,
  headers: { "Content-Type": "application/json" },
});

const _event_pricing_axios = axios.create({
  baseURL: process.env.REACT_APP_EVENT_PRICING_API_URL,
  timeout: 60000,
  headers: { "Content-Type": "application/json" },
});

const configure = () => {
  _axios.interceptors.request.use((config) => {
    if (UserService.isLoggedIn()) {
      const cb = () => {
        config.headers.Authorization = `Bearer ${UserService.getToken()}`;
        return Promise.resolve(config);
      };
      return UserService.updateToken(cb);
    }
  });
  _event_pricing_axios.interceptors.request.use((config) => {
    if (UserService.isLoggedIn()) {
      const cb = () => {
        config.headers.Authorization = `Bearer ${UserService.getToken()}`;
        return Promise.resolve(config);
      };
      return UserService.updateToken(cb);
    }
  });
};

const getAxiosClient = () => _axios;
const getEventPricingAxios = () => _event_pricing_axios;

const HttpService = {
  HttpMethods,
  configure,
  getAxiosClient,
  getEventPricingAxios,
};

export default HttpService;
