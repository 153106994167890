import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TextInput from "src/components/generic/inputs/TextInput";
import ParameterMultiSelect from "../../generic/inputs/ParameterMultiSelect";
import ProductMultiSelect from "../../generic/ProductMultiSelect";

const Targets = (props) => {
  const { namePrefix } = props;
  const { control } = useFormContext();
  const { t } = useTranslation();
  const targetType = useWatch({
    control,
    name: "targetType",
  });
  if (targetType === "PRODUCT")
    return (
      <ProductMultiSelect name="products" label={t(`${namePrefix}.products`)} />
    );
  if (targetType === "FAMILY")
    return (
      <ParameterMultiSelect
        domain="FAMILY"
        name="families"
        label={t(`${namePrefix}.families`)}
      />
    );
  if (targetType === "GROUP")
    return (
      <ParameterMultiSelect
        domain="GROUP"
        name="groups"
        label={t(`${namePrefix}.groups`)}
      />
    );
  if (targetType === "ALL")
    return (
      <TextInput
        name="filler"
        label="Cibles"
        placeholder={t(`${namePrefix}.all`)}
        disabled
      />
    );

  return (
    <TextInput
      name="filler"
      label="Cibles"
      placeholder={t(`${namePrefix}.choose`)}
      disabled
    />
  );
};

export default Targets;
