import { Modal } from "antd";

export default function modalConfirm(content, onConfirm) {
  Modal.confirm({
    title: "Attention",
    content,
    cancelText: "Annuler",
    okText: "Confirmer",
    cancelButtonProps: {
      className:
        "text-red-500 border-red-500 hover:text-red-400 hover:border-red-400",
    },
    okButtonProps: {
      className:
        "text-orange-500 border-orange-500 hover:text-orange-400 hover:border-orange-400 hover:bg-white",
    },
    onOk: onConfirm,
  });
}
