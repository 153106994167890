import { Tabs } from "antd";
import React from "react";
import ConstraintFormContent from "src/components/constraint/form/ConstraintFormContent/ConstraintFormContent";
import { getNameWithNamePrefix } from "src/utils";

const ConstraintCompsite = (props) => {
  const { namePrefix, constraintTypes } = props;
  const items = [
    {
      label: "Left",
      key: "1",
      children: (
        <ConstraintFormContent
          namePrefix={getNameWithNamePrefix("left", namePrefix)}
          constraintTypes={constraintTypes}
        />
      ),
    },

    {
      label: "Right",
      key: "2",
      children: (
        <ConstraintFormContent
          namePrefix={getNameWithNamePrefix("right", namePrefix)}
          constraintTypes={constraintTypes}
        />
      ),
    },
  ];

  return <Tabs items={items} />;
};

export default ConstraintCompsite;
