import { GlobalOutlined, LogoutOutlined } from "@ant-design/icons";
import { Button, Select, Tooltip } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import i18n from "src/i18n";
import { fetchSupportedLanguages } from "src/redux/api/supportedLanguages";
import { updateSelectedlanguage } from "src/redux/slices/parameterSlice";
import UserService from "src/services/UserService";
import modalConfirm from "src/utils/modalConfirm";
import awb_logo from "../../../assets/images/awb_logo.png";

export default function Navbar(props) {
  const { pages } = props;
  const path = useLocation().pathname;
  const { t } = useTranslation(null, { keyPrefix: "navbar" });
  const dispatch = useDispatch();
  const languages = useSelector(
    (state) => state?.supportedLanguages?.supportedLanguages
  );
  const selectedLang = useSelector(
    (state) => state?.selectedLanguage?.selectedLanguage
  );
  useEffect(() => {
    if (languages.length === 0) {
      dispatch(fetchSupportedLanguages());
    }
  }, [dispatch, languages.length]);

  useEffect(() => {
    i18n.changeLanguage(selectedLang);
  }, [selectedLang]);

  return (
    <nav className="bg-white">
      <div className="px-12 py-3 flex items-center justify-between">
        <div className="flex justify-start items-center">
          <img
            className="block h-12 w-auto mr-12"
            src={awb_logo}
            alt="Your Company"
          />
          <div className="flex justify-start items-center">
            {pages.map((page) => (
              <Link to={page.path} key={page.path}>
                <div
                  className={
                    "mx-4 text-sm hover:text-orange-300 duration-300 capitalize " +
                    (page.path === path ? "text-orange-500" : "text-black")
                  }
                >
                  {t(page.name)}
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center rounded-md border border-gray-200 mr-12 overflow-hidden">
            <div className="flex justify-center items-center p-1 border-r border-gray-200">
              <GlobalOutlined style={{ fontSize: "22px" }} />
            </div>
            <Select
              defaultValue={selectedLang}
              className="w-28"
              fieldNames={{ label: "name", value: "functionalId" }}
              options={languages}
              onChange={(value) => {
                dispatch(updateSelectedlanguage(value));
              }}
            />
          </div>
          <Tooltip title="Déconnexion">
            <Button
              shape="circle"
              size="large"
              className="flex justify-center items-center"
              onClick={() =>
                modalConfirm(
                  "Voullez vous vous déconnecter?",
                  UserService.doLogout
                )
              }
            >
              <LogoutOutlined style={{ fontSize: "25px" }} />
            </Button>
          </Tooltip>
        </div>
      </div>
    </nav>
  );
}
