import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import { mapRecommendationToData } from "src/utils/mappers/recommendationMapper";
import Form from "src/components/generic/Form";
import InternalisationInput from "src/components/generic/inputs/InternalisationInput";
import { RECOMMENDATION_DEFAULT } from "src/utils/defaultsSupplier";
import { RECOMMENDATION_VALIDATION_SCHEMA } from "src/utils/validationSchemas/recommendationSchema";
import TextInput from "src/components/generic/inputs/TextInput";
import SelectInput from "src/components/generic/inputs/SelectInput";
import ConstraintsAdd from "src/components/constraint/form/ConstraintsAdd";
import { AimOutlined, OrderedListOutlined } from "@ant-design/icons";
import { getTargetTypes } from "src/utils/getTargetTypes";
import Targets from "src/components/eventPricing/Targets";
import { RECOMMENDATION_BASE_PATH } from "src/routes/paths";
import CardDisplay from "src/components/generic/CardDisplay";
import VersionsTimeLine from "src/components/product/form/generic/VersionsTimeLine";
import { getRecommendationVersions } from "src/api/recommendation";
import Loading from "src/components/generic/Loading";
import { Error500 } from "src/pages/error";
import useVersions from "src/hooks/normalHooks/useVersions";

const RecommendationForm = (props) => {
  const {
    onSubmit,
    recommendation,
    submitDisabled,
    additionalButtons,
    isUpdateForm,
    formDisabled,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { versions, isLoading, isError } = useVersions(
    recommendation?.id,
    getRecommendationVersions
  );

  if (isLoading) return <Loading />;
  if (isError) return <Error500 />;
  return (
    <div className="grid grid-cols-5 gap-2">
      <CardDisplay title="Versions" titleCentered={true}>
        <VersionsTimeLine versions={versions} path="/recommendations/update/" />
      </CardDisplay>
      <div className="col-span-4">
        <Form
          formDisabled={formDisabled}
          onSubmit={onSubmit}
          submitDisabled={submitDisabled}
          defaultValues={
            recommendation
              ? mapRecommendationToData(recommendation)
              : RECOMMENDATION_DEFAULT
          }
          onCancel={() => navigate(RECOMMENDATION_BASE_PATH)}
          validationSchema={RECOMMENDATION_VALIDATION_SCHEMA}
          additionalButtons={additionalButtons}
        >
          <Row gutter={[16, 12]}>
            <Col span={12}>
              <TextInput
                name="functionalId"
                label={t("recommendation.functionalId")}
                disabled={isUpdateForm}
              />
            </Col>
            <Col span={12}>
              <InternalisationInput
                name="description"
                label={t("recommendation.description")}
              />
            </Col>
            <Col span={12}>
              <SelectInput
                name="targetType"
                label={t("eligibleAct.targetType")}
                data={getTargetTypes()}
                startAdornment={<AimOutlined style={{ fontSize: "25px" }} />}
              />
            </Col>

            <Col span={12}>
              <Targets namePrefix="recomended" />
            </Col>
            <Col span={24}>
              <TextInput
                name="order"
                label={t("recommendation.order")}
                startAdornment={
                  <OrderedListOutlined style={{ fontSize: "25px" }} />
                }
              />
            </Col>
            <Col span={24}>
              <h2>{t("recommendation.constraints")}</h2>
              <ConstraintsAdd />
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};
export default RecommendationForm;
