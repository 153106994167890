import axios from "axios";
import { MEDIA_PATH } from "src/api/apiPaths";
import HttpService from "../../services/HttpService";

const instance = HttpService.getAxiosClient();

export const getImageUploadUrl = async (filename, extension, functionalId) => {
  const res = await instance.get(`${MEDIA_PATH}upload`, {
    params: {
      filename,
      extension,
      functionalId,
    },
  });
  return res.data;
};

export const uploadFile = async (url, file) => {
  const res = await axios.put(url, file, {
    headers: {
      "Content-Type": file.type,
      "x-amz-acl": "public-read",
    },
  });
  return res.data;
};

export const getDownloadUrl = async (id, functionalId) => {
  const res = await instance.get(`${MEDIA_PATH}download`, {
    params: {
      id,
      functionalId,
    },
  });
  return res.data;
};
