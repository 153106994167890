import {
  mapDataToInternationalisaion,
  mapInternationalisationToData,
} from "../mapperHelpers";

export const mapDataToParameter = (data, domain) => {
  const additionalFields = {};
  domain.domainInputList.forEach(
    ({ name }) => (additionalFields[name] = data[name])
  );
  return {
    functionalId: data.functionalId,
    name: data.name,
    domain: domain.id,
    active: !!data.active,
    descriptions: mapDataToInternationalisaion(data.description),
    additionalFields,
  };
};

export const mapParameterToData = (parameter) => {
  return {
    functionalId: parameter.functionalId,
    name: parameter.name,
    active: parameter.active,
    description: mapInternationalisationToData(parameter.descriptions),
    ...parameter.additionalFields,
  };
};
