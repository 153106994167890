import { useQuery } from "react-query";
import { getAllModules } from "src/api";
import DataMultiSelect from "src/components/generic/inputs/DataMultiSelect";

export default function ModuleMultiSelect(props) {
  const { name, label, className } = props;
  const { data, isLoading } = useQuery("modules", getAllModules);
  return (
    !isLoading && (
      <DataMultiSelect
        name={name}
        label={label}
        className={className}
        data={data.map((e) => ({ functionalId: e, name: e }))}
      />
    )
  );
}
