import {
  mapDataToInternationalisaion,
  mapInternationalisationToData,
} from "../../mapperHelpers";

export const mapDataToCard = (data) => {
  const defaultTheme = data.themes?.[0]?.functionalId;
  const themeList = data.themes.map((d) => mapDataToTheme(d));

  return {
    debitType: data.debitType,
    type: "card",
    defaultTheme,
    themeList,
  };
};

const mapDataToTheme = (data) => {
  return {
    functionalId: data.functionalId,
    descriptions: mapDataToInternationalisaion(data.themeDescription),
    label: mapDataToInternationalisaion(data.themeLabel),
    details: mapDataToInternationalisaion(data.themeDetails),
    thumbnail: data.thumbnail,
    largeImage: data.largeImage,
    formulaList: mapDataToFormulas(data),
    additionalPrice: data.additionalPrice,
    salable: data.salable,
  };
};

const mapDataToFormulas = (data) => {
  return data.formulas.map((formula) => ({
    functionalId: formula.formulaCode,
    label: mapDataToInternationalisaion(formula.formulaLabel),
    additionalPrice: formula.additionalPrice,
    plafonds: formula.plafonds,
    caps: formula.caps,
    salable: formula.salable,
  }));
};

export const mapCardToData = (card) => {
  return {
    debitType: card.debitType,
    themes: card.themeList.map((theme) => mapThemeToData(theme)),
  };
};

const mapThemeToData = (theme) => {
  return {
    functionalId: theme.functionalId,
    themeDescription: mapInternationalisationToData(theme.descriptions),
    themeLabel: mapInternationalisationToData(theme.label),
    themeDetails: mapInternationalisationToData(theme.details),
    thumbnail: theme.thumbnail,
    largeImage: theme.largeImage,
    additionalPrice: theme.additionalPrice,
    salable: theme.salable,
    formulas: mapFormulasToData(theme.formulaList),
  };
};

const mapFormulasToData = (formulas) => {
  return formulas.map((formula) => ({
    formulaCode: formula.functionalId,
    formulaLabel: mapInternationalisationToData(formula.label),
    additionalPrice: formula.additionalPrice,
    plafonds: formula.plafonds,
    caps: formula.caps,
    salable: formula.salable,
  }));
};
