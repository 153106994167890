import { PARAMETER_PATH } from "src/api/apiPaths";
import HttpService from "../../services/HttpService";

const instance = HttpService.getAxiosClient();

export const getParametersByDomain = async (domain) => {
  const res = await instance.get(PARAMETER_PATH, {
    params: {
      domain,
    },
  });
  return res.data;
};

export const getDomains = async () => {
  const res = await instance.get("/parameters/domains");
  return res.data;
};

export const addParameter = async (parameter) => {
  const res = await instance.post(PARAMETER_PATH, parameter);
  return res.data;
};

export const updateParameter = async (id, parameter) => {
  const res = await instance.put(`${PARAMETER_PATH}${id}`, parameter);
  return res.data;
};

export const getParameterById = async (id) => {
  const res = await instance.get(`${PARAMETER_PATH}${id}`);
  return res.data;
};
