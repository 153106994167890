import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  createRecommendationDraft,
  getRecommendationById,
  publishRecommendationDraft,
  unpublishRecommendationDraft,
  updateRecommendation,
} from "src/api/recommendation";
import CardDisplay from "src/components/generic/CardDisplay";
import Loading from "src/components/generic/Loading";
import VersioningButtons from "src/components/generic/VersioningButtons";
import RecommendationForm from "src/components/recommendation/form/RecommendationForm";
import { Error500 } from "src/pages/error";
import { RECOMMENDATION_BASE_PATH } from "src/routes/paths";
import { mapDataToRecommendation } from "src/utils/mappers/recommendationMapper";

export default function RecommendationUpdatePage() {
  const { recommendationId } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    data: recommendation,
    isLoading,
    isError,
  } = useQuery(["get-recommendation", recommendationId], () =>
    getRecommendationById(recommendationId)
  );
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const { t } = useTranslation();

  const onSubmit = async (data) => {
    try {
      setSubmitDisabled(true);
      const mappedRecommendation = mapDataToRecommendation(data);
      await updateRecommendation(mappedRecommendation, recommendationId);
      queryClient.invalidateQueries({ queryKey: ["get-recommendation"] });
      navigate(RECOMMENDATION_BASE_PATH, {
        state: {
          toastContent: "Recommendation added successfully!",
        },
      });
    } catch (err) {
      toast.error("There was an error, couldn't update Recommendation");
    } finally {
      setSubmitDisabled(false);
    }
  };
  if (isLoading) return <Loading />;
  if (isError) return <Error500 />;
  return (
    <div className="p-3">
      <CardDisplay titleCentered title={t("recommendation.update")}>
        <RecommendationForm
          submitDisabled={submitDisabled}
          onSubmit={onSubmit}
          recommendation={recommendation}
          formDisabled={recommendation.version !== "draft"}
          isUpdateForm={true}
          additionalButtons={
            <VersioningButtons
              element={recommendation}
              createDraft={createRecommendationDraft}
              publishDraft={publishRecommendationDraft}
              unpublishDraft={unpublishRecommendationDraft}
              updateUrl="/recommendations/update/"
            />
          }
        />
      </CardDisplay>
    </div>
  );
}
