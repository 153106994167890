import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DollarCircleOutlined,
  EuroOutlined,
  PercentageOutlined,
} from "@ant-design/icons";
import { Col, Row } from "antd";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ConstraintsAdd from "src/components/constraint/form/ConstraintsAdd";
import ParameterSelect from "src/components/generic/inputs/ParameterSelect";
import TextInput from "src/components/generic/inputs/TextInput";
import SelectInput from "../inputs/SelectInput";

export default function PricingForm(props) {
  const { namePrefix } = props;
  const { t } = useTranslation();
  const { control } = useFormContext();
  const type = useWatch({
    control,
    name: `${namePrefix}.type`,
  });

  return (
    <Row gutter={[16, 12]}>
      <Col span={4}>
        <SelectInput
          name={`${namePrefix}.type`}
          label="Type"
          data={[
            {
              functionalId: "FLAT",
              name: t("priceType.flat"),
            },
            {
              functionalId: "PERCENTAGE",
              name: t("priceType.percentage"),
            },
          ]}
        />
      </Col>
      <Col span={4}>
        <TextInput
          label={t("pricing.price")}
          type="number"
          name={`${namePrefix}.price`}
          startAdornment={<DollarCircleOutlined style={{ fontSize: "25px" }} />}
          endAdornment={
            type === "PERCENTAGE" && <span className="text-lg ml-2">%</span>
          }
        />
      </Col>
      <Col span={4}>
        <TextInput
          name={`${namePrefix}.flexibility`}
          label={t("pricing.flexibility")}
          startAdornment={<PercentageOutlined style={{ fontSize: "25px" }} />}
        />
      </Col>

      {type === "PERCENTAGE" ? (
        <>
          <Col span={4}>
            <TextInput
              name={`${namePrefix}.min`}
              label={t("pricing.min")}
              startAdornment={
                <ArrowDownOutlined style={{ fontSize: "25px" }} />
              }
            />
          </Col>
          <Col span={4}>
            <TextInput
              name={`${namePrefix}.max`}
              label={t("pricing.max")}
              startAdornment={<ArrowUpOutlined style={{ fontSize: "25px" }} />}
            />
          </Col>
        </>
      ) : null}
      {type === "FLAT" ? (
        <>
          <Col span={4}>
            <ParameterSelect
              name={`${namePrefix}.currency`}
              label={t("pricing.currency")}
              startAdornment={<EuroOutlined style={{ fontSize: "25px" }} />}
              domain="CURRENCY"
            />
          </Col>
        </>
      ) : null}

      <Col span={24}>
        <p className="font-medium">{t("pricing.constraints")}</p>
        <ConstraintsAdd namePrefix={namePrefix} className="flex-wrap" />
      </Col>
    </Row>
  );
}
