import PropTypes from "prop-types";
import { CAP_DEFAULT } from "src/utils/defaultsSupplier";
import ArrayAccordion from "../../../../generic/ArrayAccordion";
import CapForm from "../CapForm";

export default function Cap(props) {
  const { namePrefix } = props;
  return (
    <ArrayAccordion
      name={`${namePrefix}.caps`}
      translationPrefix="cap"
      Form={CapForm}
      defaultValues={CAP_DEFAULT}
    />
  );
}

Cap.propTypes = {
  namePrefix: PropTypes.string.isRequired,
};
