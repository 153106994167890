import {
  mapDataToInternationalisaion,
  mapDataToTargets,
  mapInternationalisationToData,
  mapTargetsToData,
} from "src/utils/mappers/mapperHelpers";

export const mapRecommendationToData = (recommendation) => {
  return {
    functionalId: recommendation.functionalId,
    order: recommendation.order,
    description: mapInternationalisationToData(recommendation.description),
    targetType: recommendation.targetType,
    products: [],
    families: [],
    groups: [],
    ...mapTargetsToData(recommendation),
    constraints: recommendation.constraints,
  };
};

export const mapDataToRecommendation = (recommendation) => {
  return {
    functionalId: recommendation.functionalId,
    order: recommendation.order,
    description: mapDataToInternationalisaion(recommendation.description),
    targetType: recommendation.targetType,
    targets: mapDataToTargets(recommendation),
    constraints: recommendation.constraints,
  };
};
