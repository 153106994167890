import PropTypes from "prop-types";
import useParameters from "src/hooks/normalHooks/useParameters";
import { t } from "i18next";
import { Tag } from "antd";

const ProductFilter = (props) => {
  const { onFilter, currentFilter } = props;
  const { data, isLoading } = useParameters("FAMILY");

  return (
    <div className="w-5/6 py-3 flex overflow-x-scroll">
      <Tag
        onClick={() => {
          onFilter("all");
        }}
        className="py-1 cursor-pointer"
        color={currentFilter === "all" ? "orange" : ""}
      >
        {t("all.all")}
      </Tag>
      {!isLoading &&
        data.map((param) => (
          <Tag
            onClick={() => {
              onFilter(param.functionalId);
            }}
            className="py-1 cursor-pointer capitalize ml-2"
            key={param.id}
            color={currentFilter === param.functionalId ? "orange" : ""}
          >
            {param.name}
          </Tag>
        ))}
    </div>
  );
};
ProductFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
};
export default ProductFilter;
