import { QrcodeOutlined, ShopOutlined, TagOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import InternalisationInput from "../../../generic/inputs/InternalisationInput";
import ParameterMultiSelect from "../../../generic/inputs/ParameterMultiSelect";
import TextInput from "../../../generic/inputs/TextInput";

const ConsentForm = (props) => {
  const { namePrefix } = props;
  const { t } = useTranslation();
  return (
    <Row gutter={[16, 8]} className="mb-5">
      <Col span={12}>
        <TextInput
          name={`${namePrefix}.functionalId`}
          label={t("consent.functionalId")}
          startAdornment={<QrcodeOutlined style={{ fontSize: "25px" }} />}
        />
      </Col>
      <Col span={12}>
        <InternalisationInput
          name={`${namePrefix}.descriptions`}
          label={t("consent.descriptions")}
          startAdornment={<TagOutlined style={{ fontSize: "25px" }} />}
        />
      </Col>
      <Col span={24}>
        <ParameterMultiSelect
          startAdornment={<ShopOutlined style={{ fontSize: "25px" }} />}
          name={`${namePrefix}.canals`}
          label={t("consent.canals")}
          domain="CANAL"
        />
      </Col>
    </Row>
  );
};

export default ConsentForm;
