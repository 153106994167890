import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "src/components/generic/Loading";
import {
  createEventPricingDraft,
  getEventPricingById,
  publishEventPricingDraft,
  unpublishEventPricingDraft,
  updateEventPricing,
} from "../../../api/eventPricing";
import EventPricingForm from "../../../components/eventPricing/EventPricingForm";
import { mapDataToEventPricing } from "../../../utils/mappers/eventPricingMapper";

import { useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { Error500 } from "src/pages/error";
import CardDisplay from "src/components/generic/CardDisplay";
import { EVENT_PRICING_BASE_PATH } from "src/routes/paths";
import VersioningButtons from "src/components/generic/VersioningButtons";

export default function EventPricingUpdatePage() {
  const { eventPricingId } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    data: eventPricing,
    isLoading,
    isError,
  } = useQuery(["get-event-pricing", eventPricingId], () =>
    getEventPricingById(eventPricingId)
  );

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const onSubmit = async (data) => {
    try {
      setSubmitDisabled(true);
      const _eventPricing = mapDataToEventPricing(data);
      await updateEventPricing(_eventPricing, eventPricingId);
      queryClient.invalidateQueries({ queryKey: ["get-event-pricing"] });
      navigate(EVENT_PRICING_BASE_PATH, {
        state: {
          toastContent: "Event Pricings updated successfully!",
        },
      });
    } catch (err) {
      toast.error("There was an error, couldn't update Event Pricings");
    } finally {
      setSubmitDisabled(false);
    }
  };

  if (isLoading) return <Loading />;
  if (isError) return <Error500 />;

  return (
    <div className="p-3">
      <CardDisplay title={t("eventPricing.update")} titleCentered>
        <EventPricingForm
          submitDisabled={submitDisabled}
          onSubmit={onSubmit}
          formDisabled={eventPricing.version !== "draft"}
          eventPricing={eventPricing}
          isUpdateForm={true}
          additionalButtons={
            <VersioningButtons
              element={eventPricing}
              createDraft={createEventPricingDraft}
              publishDraft={publishEventPricingDraft}
              unpublishDraft={unpublishEventPricingDraft}
              updateUrl="/event-pricings/update/"
            />
          }
        />
      </CardDisplay>
    </div>
  );
}
