import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { getAllEligibleActs } from "../../../api/eligibleAct";
import Loading from "../../../components/generic/Loading";
import { ELIGIBLE_ACT_ADD_PATH } from "../../../routes/paths";
import EventCard from "../../../components/generic/EventCard";
import { useQuery } from "react-query";
import { Button, Empty } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Error500 } from "src/pages/error";
import CustomPagination from "src/components/generic/CustomPagination";

const ELEMENT_PER_PAGE = 20;

const EligibleActsListingPage = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [page, setPage] = useState(1);

  const {
    data: eligibleActs,
    isLoading,
    isError,
  } = useQuery(["get-eligible-acts", page], () =>
    getAllEligibleActs(page - 1, ELEMENT_PER_PAGE)
  );

  useEffect(() => {
    if (state?.toastContent) toast.success(state?.toastContent);
  }, [state]);

  if (isError) return <Error500 />;
  if (isLoading) return <Loading />;

  return (
    <div className="h-full">
      <div className="w-full h-full flex flex-col p-5">
        <div className="flex justify-end mb-3 ">
          <Link to={ELIGIBLE_ACT_ADD_PATH}>
            <Button
              type="primary"
              className="bg-orange-500 text-white flex items-center"
            >
              <PlusOutlined sx={{ fontSize: "20px" }} /> {t("crud.add")}{" "}
              {t("subnav.eligibleAct")}
            </Button>
          </Link>
        </div>
        <ToastContainer />

        {eligibleActs?.content.length > 0 ? (
          <div className="grid grid-cols-5 gap-4 w-full mb-2">
            {eligibleActs?.content.map((act) => (
              <Link key={act.id} to={`/eligible-acts/update/${act.id}`}>
                <EventCard event={act} />
              </Link>
            ))}
          </div>
        ) : (
          <div className="h-96">
            <Empty />
          </div>
        )}
        <CustomPagination
          current={page}
          total={eligibleActs.totalElements}
          pageSize={ELEMENT_PER_PAGE}
          onChange={(value) => setPage(value)}
        />
      </div>
    </div>
  );
};

export default EligibleActsListingPage;
