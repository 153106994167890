import { FileWordOutlined, TagOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import MultiChipsInput from "src/components/generic/inputs/MultiChipInput";
import SelectInput from "src/components/generic/inputs/SelectInput";
import TextInput from "src/components/generic/inputs/TextInput";

export default function AdditionalAttributeForm(props) {
  const { namePrefix } = props;
  const { control } = useFormContext();
  const attribute = useWatch({
    control,
    name: namePrefix,
  });

  const { t } = useTranslation();

  const getInput = (attr) => {
    switch (attr.type) {
      case "Text":
        return (
          <TextInput
            value="text"
            key="Text"
            name={`${namePrefix}.value[Text]`}
            label={t("additionalAttributes.value")}
            startAdornment={<FileWordOutlined style={{ fontSize: "25px" }} />}
          />
        );
      case "Number":
        return (
          <TextInput
            name={`${namePrefix}.value[Number]`}
            label={t("additionalAttributes.value")}
            key="Number"
            type="number"
            startAdornment={<FileWordOutlined style={{ fontSize: "25px" }} />}
          />
        );
      case "ListNumber":
        return (
          <MultiChipsInput
            name={`${namePrefix}.value[ListNumber]`}
            label={t("additionalAttributes.values")}
            key="ListNumber"
            type="number"
          />
        );
      case "ListText":
        return (
          <MultiChipsInput
            name={`${namePrefix}.value[ListText]`}
            key="ListText"
            label={t("additionalAttributes.values")}
            type="text"
          />
        );
      default:
        return <div>{t("additionalAttributes.notSupported")}</div>;
    }
  };

  return (
    <div>
      <Row gutter={[16, 12]} className="m-1 mb-5">
        <Col span={12}>
          <TextInput
            name={`${namePrefix}.name`}
            label="Attribute Name"
            startAdornment={<TagOutlined style={{ fontSize: "25px" }} />}
          />
        </Col>
        <Col span={12}>
          <SelectInput
            name={`${namePrefix}.type`}
            label="Attribute Type"
            data={[
              {
                functionalId: "Text",
                name: "Text",
              },
              {
                functionalId: "Number",
                name: "Nombre",
              },
              {
                functionalId: "ListText",
                name: "List de Text",
              },
              {
                functionalId: "ListNumber",
                name: "List de Nombre",
              },
            ]}
          />
        </Col>
        <Col span={24}>{getInput(attribute)}</Col>
      </Row>
    </div>
  );
}
