import {
  DollarCircleOutlined,
  QrcodeOutlined,
  TagOutlined,
} from "@ant-design/icons";
import { Col, Row, Tabs } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import SwitchInput from "src/components/generic/inputs/SwitchInput";
import InternalisationInput from "../../../../generic/inputs/InternalisationInput";
import TextInput from "../../../../generic/inputs/TextInput";
import Cap from "../Cap";
import Plafond from "../Plafond";

export default function FormulaForm(props) {
  const { namePrefix } = props;
  const { t } = useTranslation();

  const items = [
    {
      label: "Info",
      key: "1",
      children: (
        <Row gutter={[16, 12]} className="m-5">
          <Col span={12}>
            <TextInput
              name={`${namePrefix}.formulaCode`}
              label={t("formulas.codeFormula")}
              startAdornment={<QrcodeOutlined style={{ fontSize: "25px" }} />}
            />
          </Col>
          <Col span={12}>
            <InternalisationInput
              name={`${namePrefix}.formulaLabel`}
              label="Label"
              startAdornment={<TagOutlined style={{ fontSize: "25px" }} />}
            />
          </Col>
          <Col span={12}>
            <TextInput
              name={`${namePrefix}.additionalPrice`}
              label={t("formulas.additionalPrice")}
              type="number"
              startAdornment={
                <DollarCircleOutlined style={{ fontSize: "25px" }} />
              }
            />
          </Col>
          <Col span={12}>
            <SwitchInput
              label={t("formulas.salable")}
              name={`${namePrefix}.salable`}
            />
          </Col>
        </Row>
      ),
    },

    {
      label: t("plafond.title"),
      key: "2",
      children: <Plafond namePrefix={namePrefix} />,
    },

    {
      label: t("cap.title"),
      key: "3",
      children: <Cap namePrefix={namePrefix} />,
    },
  ];

  return <Tabs items={items} />;
}

FormulaForm.propTypes = {
  namePrefix: PropTypes.string.isRequired,
};
