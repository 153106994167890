import Bancass from "src/components/product/form/bancass/Bancass";
import BankCard from "src/components/product/form/bankCard/BankCard";
import BankOnline from "src/components/product/form/bankOnline/BankOnline";
import Dotation from "src/components/product/form/Dotation";
import Exemption from "src/components/product/form/exemption/Exemption";
import GenericProduct from "src/components/product/form/GenericProduct";
import PackInfo from "src/components/product/form/pack/PackInfo/PackInfo";
import SavingInfo from "src/components/product/form/saving/SavingInfo";
import {
  mapDataToExemption,
  mapExemptionToData,
} from "src/utils/mappers/productMapper/exemptionMapper";
import {
  mapBancassToData,
  mapDataToBancass,
} from "./mappers/productMapper/bancassMapper";
import {
  mapBankOnlineToData,
  mapDataToBankOnline,
} from "./mappers/productMapper/bankOnlineMapper";
import {
  mapCardToData,
  mapDataToCard,
} from "./mappers/productMapper/cardMapper";
import {
  mapDataToDotation,
  mapDotationToData,
} from "./mappers/productMapper/dotationMapper";
import {
  mapDataToGenericProduct,
  mapGenericProductToData,
} from "./mappers/productMapper/genericProductMapper/genericProductMapper";
import {
  mapDataToPack,
  mapPackToData,
} from "./mappers/productMapper/packMapper/packMapper";
import {
  mapDataToSaving,
  mapSavingToData,
} from "./mappers/productMapper/savingMapper";

export const familyMappers = {
  MONETIQUE: {
    mapFromData: mapDataToCard,
    mapToData: mapCardToData,
    component: <BankCard />,
  },
  ASSURANCE: {
    mapFromData: mapDataToBancass,
    mapToData: mapBancassToData,
    component: <Bancass />,
  },

  DOTATION: {
    mapFromData: mapDataToDotation,
    mapToData: mapDotationToData,
    component: <Dotation />,
  },
  EPARGNE: {
    mapFromData: mapDataToSaving,
    mapToData: mapSavingToData,
    component: <SavingInfo />,
  },
  BANK_ONLINE: {
    mapFromData: mapDataToBankOnline,
    mapToData: mapBankOnlineToData,
    component: <BankOnline />,
  },
  PACK: {
    mapFromData: mapDataToPack,
    mapToData: mapPackToData,
    component: <PackInfo />,
  },
  GENERIC_PRODUCT: {
    mapFromData: mapDataToGenericProduct,
    mapToData: mapGenericProductToData,
    component: <GenericProduct />,
  },

  EXEMPTION: {
    mapFromData: mapDataToExemption,
    mapToData: mapExemptionToData,
    component: <Exemption />,
  },
};
