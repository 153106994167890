import PropTypes from "prop-types";
import ArrayAccordion from "src/components/generic/ArrayAccordion";
import { MODULE_OPTION_DEFAULT } from "src/utils/defaultsSupplier";
import ModuleOptionForm from "../ModuleOptionForm/ModuleOptionForm";

export default function ModuleOption(props) {
  const { namePrefix } = props;

  return (
    <ArrayAccordion
      name={`${namePrefix}`}
      translationPrefix="moduleOptions"
      Form={ModuleOptionForm}
      defaultValues={MODULE_OPTION_DEFAULT}
      fieldsNames={["description", "additionalPrice"]}
    />
  );
}

ModuleOption.propTypes = {
  namePrefix: PropTypes.string.isRequired,
};
