import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { update } from "src/redux/slices/parameterSlice";
import useModal from "src/hooks/componentHooks/useModal";
import useParameters from "src/hooks/normalHooks/useParameters";
import ParameterForm from "../../form/ParameterForm";
import ParameterCard from "../ParameterCard";
import Loading from "src/components/generic/Loading";
import { Error500 } from "src/pages/error";
import { useDispatch } from "react-redux";
import { Button, Empty } from "antd";
import { PlusOutlined } from "@ant-design/icons";

export default function ParameterLister(props) {
  const { domain } = props;
  const { Modal, setOpen } = useModal();
  const { data, isLoading, isError, refetch } = useParameters(domain.id);
  const [parameter, setParameter] = useState(null);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  if (isLoading) return <Loading />;
  if (isError) return <Error500 />;

  return (
    <div className="w-full p-4 shadow-md mb-2 ">
      <ToastContainer />
      <div className="flex justify-between align-baseline">
        <h1 className="font-semibold">{t(`parameters.${domain.name}`)}</h1>
        <Button
          type="primary"
          className="mb-2 bg-orange-500 text-white flex items-center"
          onClick={() => {
            setOpen(true);
            setParameter(null);
          }}
        >
          <PlusOutlined /> {t("crud.add")} {t(`parameters.${domain.name}`)}
        </Button>
      </div>
      {data.length === 0 ? (
        <Empty description={t("data.noDataFound")} />
      ) : (
        <div className="grid grid-cols-1 gap-4 sm:grid md:grid-cols-2 md:gap-4 lg:grid lg:grid-cols-4 lg:gap-4">
          {data.map((param, index) => (
            <div
              key={index}
              className="cursor-pointer"
              onClick={() => {
                setOpen(true);
                setParameter(param);
              }}
            >
              <ParameterCard parameter={param} />
            </div>
          ))}
        </div>
      )}

      <Modal
        title={
          (parameter ? t("crud.update") : t("crud.add")) +
          " " +
          t(`parameters.${domain.name}`)
        }
      >
        <ParameterForm
          domain={domain}
          parameter={parameter}
          close={() => {
            setOpen(false);
            setParameter(null);
          }}
          onParameterAdd={(p) => {
            toast.success(t("modal.add"));
            if (domain.id === "SUPPORTED_LANGUAGE")
              refetch().then((res) => dispatch(update(res.data)));
            refetch();
          }}
          onParameterUpdate={(p) => {
            toast.success(t("modal.update"));
            if (domain.id === "SUPPORTED_LANGUAGE") {
              refetch().then((res) => dispatch(update(res.data)));
            }
            refetch();
          }}
        />
      </Modal>
    </div>
  );
}

ParameterLister.propTypes = {
  domain: PropTypes.object.isRequired,
};
